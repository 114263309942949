import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toFixedSmart',
    standalone: false
})
@Injectable({
  providedIn: 'root'
})
export class ToFixedSmartPipe implements PipeTransform {

  transform(value: number, numNonZeroDecimalDigits: number): string {
    let strValue = value.toString();
    let index = strValue.indexOf('.');

    if (index === -1)
      return strValue;
    
    index++;

    let countNonZero = 0;
    while (index < strValue.length && countNonZero < numNonZeroDecimalDigits) {
      if (strValue[index] !== '0')
        countNonZero++;
      index++;
    }

    return strValue.substring(0, index);
  }

}
