import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class NeedTenancyAdminGuard  {
	constructor(private authenticationService: AuthenticationService, private router: Router) {
	}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
		const redirectUrl = route['_routerState']['url'];

		if (this.authenticationService.hasRole('tenancy-admin'))
			return true;
  
		if (!this.authenticationService.isAuthenticated()) {
			this.router.navigateByUrl(
				this.router.createUrlTree(
					['/login'], {
						queryParams: {
							redirectUrl
						}
					})
			);
		}
  
	  return false;
	}
  }
  