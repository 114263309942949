import { Pipe, PipeTransform } from '@angular/core';

// Tries to format a number/string within given limit of characters
@Pipe({
    name: 'toFixedWidth',
    standalone: false
})
export class ToFixedWidthPipe implements PipeTransform {

  transform(num: unknown, fixed: number): string {
    if (typeof num === "number") {

      if (num === 0)
        return "0";

      let digits = Math.floor(Math.log10(Math.abs(num))) + 1;
      if (num < 1)
        digits++;

      if (digits === fixed)
        return num.toFixed(0);
      else if (digits < fixed) {
        const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed - digits) + '})?');
        let trimmed = num.toFixed(10).match(re)[0];

        if (trimmed.indexOf('.') !== -1) {
          // Remove all decimal zeroes on the right
          for (let i = trimmed.length-1; i >= 0; i--) {
            if (trimmed[i] !== '0') {
              trimmed = trimmed.substring(0, i+1);
              break;
            }
          }
        }

        if (trimmed.endsWith('.'))
          trimmed = trimmed.substring(0, trimmed.length-1);

        return trimmed;
      } else { // digits > fixed
        return num.toExponential(fixed >= 5 ? fixed-5 : 0);
      }
    } else {
      let str = num.toString();
      if (str.length > fixed)
        return str.substring(0, fixed-1) + "…";
      return str;
    }
  }

}
