import { renderIcon as C } from "../icon.renderer.js";
const L = "tag",
  d = ["tag", C({
    outline: '<path d="M10.5 9C9.67 9 9 9.67 9 10.5C9 11.33 9.67 12 10.5 12C11.33 12 12 11.33 12 10.5C12 9.67 11.33 9 10.5 9ZM31.04 18.82L17.26 5.11C16.91 4.76 16.18 4.04 15.09 4.03L5.03 4C4.77 4 4.51 4.1 4.32 4.29C4.13 4.48 4.03 4.73 4.03 4.99L4 14.97C4 15.85 4.37 16.76 4.96 17.34L18.75 31.05C19.36 31.66 20.18 32 21.04 32H21.06C21.92 32 22.73 31.67 23.33 31.07L31.06 23.38C32.32 22.13 32.31 20.08 31.04 18.82ZM29.64 21.97L21.91 29.66C21.68 29.89 21.38 30.01 21.05 30.01C20.72 30.01 20.4 29.88 20.16 29.64L6.38 15.93C6.16 15.71 6.01 15.31 6.01 14.97L6.03 6L15.08 6.03C15.27 6.03 15.48 6.17 15.84 6.53L29.62 20.24C30.11 20.73 30.12 21.51 29.64 21.98V21.97Z"/>',
    outlineAlerted: '<path d="M26.9039 1.64597L21.2222 11.1156C20.9526 11.4981 20.9281 11.9946 21.1588 12.4002C21.3896 12.8058 21.8363 13.0517 22.3148 13.0364H33.6881C34.1666 13.0517 34.6134 12.8058 34.8441 12.4002C35.0748 11.9946 35.0503 11.4981 34.7808 11.1156L29.0991 1.64597C28.8711 1.26889 28.4532 1.03711 28.0015 1.03711C27.5497 1.03711 27.1319 1.26889 26.9039 1.64597Z"/><path d="M20.5371 8.37048L17.26 5.11C16.91 4.76 16.18 4.04 15.09 4.03L5.03 4C4.77 4 4.51 4.1 4.32 4.29C4.13 4.48 4.03 4.73 4.03 4.99L4 14.97C4 15.85 4.37 16.76 4.96 17.34L18.75 31.05C19.36 31.66 20.18 32 21.04 32H21.06C21.92 32 22.73 31.67 23.33 31.07L31.06 23.38C32.32 22.13 32.31 20.08 31.04 18.82L27.2374 15.0367H24.3901L29.62 20.24C30.11 20.73 30.12 21.51 29.64 21.98V21.97L21.91 29.66C21.68 29.89 21.38 30.01 21.05 30.01C20.72 30.01 20.4 29.88 20.16 29.64L6.38 15.93C6.16 15.71 6.01 15.31 6.01 14.97L6.03 6L15.08 6.03C15.27 6.03 15.48 6.17 15.84 6.53L19.4704 10.142C19.4916 10.1073 19.5136 10.0728 19.5362 10.0387L20.5371 8.37048Z"/><path d="M9 10.5C9 9.67 9.67 9 10.5 9C11.33 9 12 9.67 12 10.5C12 11.33 11.33 12 10.5 12C9.67 12 9 11.33 9 10.5Z"/>',
    outlineBadged: '<path d="M10.5 9C9.67 9 9 9.67 9 10.5C9 11.33 9.67 12 10.5 12C11.33 12 12 11.33 12 10.5C12 9.67 11.33 9 10.5 9ZM31.04 18.82L17.26 5.11C16.91 4.76 16.18 4.04 15.09 4.03L5.03 4C4.77 4 4.51 4.1 4.32 4.29C4.13 4.48 4.03 4.73 4.03 4.99L4 14.97C4 15.85 4.37 16.76 4.96 17.34L18.75 31.05C19.36 31.66 20.18 32 21.04 32H21.06C21.92 32 22.73 31.67 23.33 31.07L31.06 23.38C32.32 22.13 32.31 20.08 31.04 18.82ZM29.64 21.97L21.91 29.66C21.68 29.89 21.38 30.01 21.05 30.01C20.72 30.01 20.4 29.88 20.16 29.64L6.38 15.93C6.16 15.71 6.01 15.31 6.01 14.97L6.03 6L15.08 6.03C15.27 6.03 15.48 6.17 15.84 6.53L29.62 20.24C30.11 20.73 30.12 21.51 29.64 21.98V21.97Z"/><path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/>',
    solid: '<path d="M31.0405 18.82L17.2605 5.11C16.9105 4.76 16.1805 4.04 15.0905 4.03L5.03049 4C4.77049 4 4.51049 4.1 4.32049 4.29C4.13049 4.48 4.03049 4.73 4.03049 4.99L4.00049 14.97C4.00049 15.85 4.37049 16.76 4.96049 17.34L18.7505 31.05C19.3605 31.66 20.1805 32 21.0405 32H21.0605C21.9205 32 22.7305 31.67 23.3305 31.07L31.0605 23.38C32.3205 22.13 32.3105 20.08 31.0405 18.82ZM10.5005 12.2C9.56049 12.2 8.80049 11.44 8.80049 10.5C8.80049 9.56 9.56049 8.8 10.5005 8.8C11.4405 8.8 12.2005 9.56 12.2005 10.5C12.2005 11.44 11.4405 12.2 10.5005 12.2Z"/>',
    solidAlerted: '<path d="M26.9039 1.64597L21.2222 11.1156C20.9526 11.4981 20.9281 11.9946 21.1588 12.4002C21.3896 12.8058 21.8363 13.0517 22.3148 13.0364H33.6881C34.1666 13.0517 34.6134 12.8058 34.8441 12.4002C35.0748 11.9946 35.0503 11.4981 34.7808 11.1156L29.0991 1.64597C28.8711 1.26889 28.4532 1.03711 28.0015 1.03711C27.5497 1.03711 27.1319 1.26889 26.9039 1.64597Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M20.5372 8.3701L19.5361 10.0387C18.8702 11.0423 18.8203 12.3342 19.4204 13.3893C20.0232 14.4489 21.1576 15.0604 22.3394 15.0367H27.2379L31.0405 18.82C32.3105 20.08 32.3205 22.13 31.0605 23.38L23.3305 31.07C22.7305 31.67 21.9205 32 21.0605 32H21.0405C20.1805 32 19.3605 31.66 18.7505 31.05L4.96049 17.34C4.37049 16.76 4.00049 15.85 4.00049 14.97L4.03049 4.99C4.03049 4.73 4.13049 4.48 4.32049 4.29C4.51049 4.1 4.77049 4 5.03049 4L15.0905 4.03C16.1805 4.04 16.9105 4.76 17.2605 5.11L20.5372 8.3701ZM8.80049 10.5C8.80049 11.44 9.56049 12.2 10.5005 12.2C11.4405 12.2 12.2005 11.44 12.2005 10.5C12.2005 9.56 11.4405 8.8 10.5005 8.8C9.56049 8.8 8.80049 9.56 8.80049 10.5Z"/>',
    solidBadged: '<path d="M31.0405 18.82L17.2605 5.11C16.9105 4.76 16.1805 4.04 15.0905 4.03L5.03049 4C4.77049 4 4.51049 4.1 4.32049 4.29C4.13049 4.48 4.03049 4.73 4.03049 4.99L4.00049 14.97C4.00049 15.85 4.37049 16.76 4.96049 17.34L18.7505 31.05C19.3605 31.66 20.1805 32 21.0405 32H21.0605C21.9205 32 22.7305 31.67 23.3305 31.07L31.0605 23.38C32.3205 22.13 32.3105 20.08 31.0405 18.82ZM10.5005 12.2C9.56049 12.2 8.80049 11.44 8.80049 10.5C8.80049 9.56 9.56049 8.8 10.5005 8.8C11.4405 8.8 12.2005 9.56 12.2005 10.5C12.2005 11.44 11.4405 12.2 10.5005 12.2Z"/><path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/>'
  })];
export { d as tagIcon, L as tagIconName };
