import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { TestingTokenHelper } from 'src/app/charging/testing-token-helper';
import { CardChargeData, PaymentService, StripeCardChargeData } from 'src/app/payment.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-topup-credit',
    templateUrl: './topup-credit.component.html',
    styleUrls: ['./topup-credit.component.css'],
    standalone: false
})
export class TopupCreditComponent {
  opened = false;
  busy = false;
  processing = false;
  haveSavedCard: boolean;
  currency: string;

  amount = new FormControl(10);
  amountFromSaved = new FormControl(10);

  stripe: Stripe;
  elements: StripeElements;
  cardElement: StripeCardElement;

  @ViewChild('cardElement', { static: false }) cardElementNative: ElementRef;

  @Output()
  creditChanged = new EventEmitter<void>();

  // https://stripe.com/docs/testing?testing-method=tokens#visa
  private cardTestingToken = new TestingTokenHelper(window);

  constructor(private paymentService: PaymentService, private zone: NgZone) { }

  public open() {
    this.busy = true;
    this.opened = true;

    this.cardTestingToken.register();

    this.paymentService.getPaymentState().subscribe(state => {
      this.haveSavedCard = !!state.card;
      this.currency = state.currency;
      this.busy = false;

      this.paymentService.getProviderConfig('stripe').toPromise().then(stripeConfig => {
        loadStripe(stripeConfig['publicKey']).then(stripe => {
          this.stripe = stripe;
    
          this.zone.runOutsideAngular(() => {
            this.elements = this.stripe.elements({});
            this.cardElement = this.elements.create('card', { hidePostalCode: true });
          
            setTimeout(() => {
              this.cardElement.mount(this.cardElementNative.nativeElement);
            }, 0);
          });
        });
      });

    }, err => {
      window.alert($localize `Error getting card information: ${err.message}`);
      this.busy = false;
      this.opened = false;
    });
  }

  chargeSavedCard() {
    let value = parseFloat(this.amountFromSaved.value.toString());

    if (value < 1 || isNaN(value)) {
      window.alert($localize `Please enter at least 1 ${this.currency}`);
      return;
    }

    this.processing = true;
    this.paymentService.topUpFromSavedCard(Math.round(value*100)).subscribe(() => {
      this.processing = false;
      this.opened = false;
      this.creditChanged.emit();
    }, err => {
      this.processing = false;
      window.alert($localize `Failed to execute payment: ${err.message}`);
    });
  }

  chargeEnteredCard() {
    let value = parseFloat(this.amount.value.toString());

    if (value < 1 || isNaN(value)) {
      window.alert($localize `Please enter at least 1 ` + this.currency);
      return;
    }

    let chargeData: CardChargeData;

    this.processing = true;
    this.paymentService.initiateTopUp(Math.round(value * 100)).toPromise()
    .then((result: StripeCardChargeData) => {
      chargeData = result;

      return this.stripe.confirmCardPayment(result.client_secret, {
        payment_method: {
          card: this.cardTestingToken.token ? { token: this.cardTestingToken.token } : this.cardElement
        }
      });
    })
    .then(res => {
      if (res.error)
        throw res.error;
        
      return this.paymentService.completeTopUp(chargeData).toPromise();
    })
    .then(result => {
      this.processing = false;
      this.opened = false;
      this.creditChanged.emit();
    })
    .catch(err => {
      this.processing = false;
      window.alert("Failed to execute payment: " + err.message);
    });
  }

}
