import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { sharingCodeParams } from '../sharing';
import { DLProvidedMask } from '../dl-pipelines';
import { AutoMLIntegrityMetadata } from '../data-integrity-map';
import { HVReport } from '../hvreport';
import { ResultRange } from '../common';
import { BASE_URL } from '../dicom.service';

@Injectable({
  providedIn: 'root'
})
export class SubmissionBackendService {

  constructor(private http: HttpClient, @Inject(BASE_URL) private baseUrl: string) { }

  public submit(data: CdssSubmissionData, workspaceId?: string): Observable<string> {
    let params = new HttpParams();

    if (workspaceId)
      params = params.set("wid", workspaceId);

    return this.http.post(this.baseUrl + '/cdss/submission', data, { observe: 'response', params }).pipe(map(resp => {
      let location = resp.headers.get('location');
      if (location) {
        return location.split('/').pop();
      }
    }));
  }

  public getSubmission(submissionId: string, sharingCode?: string) {
    return this.http.get<CdssSubmissionItem>(this.baseUrl + `/cdss/submission/${submissionId}`, { params: sharingCodeParams(sharingCode) }).pipe(map(submission => {
      submission.createdAt = new Date(submission.createdAt); // convert from JSON string
      return submission;
    }));
  }

  public getReport(submissionId: string, sharingCode?: string) {
    return this.http.get<CdssReportData>(this.baseUrl + `/cdss/submission/${submissionId}/report`, { params: sharingCodeParams(sharingCode) });
  }

  public getHolisticReport(submissionId: string, sharingCode?: string) {
    return this.http.get<HVReport>(this.baseUrl + `/cdss/submission/${submissionId}/holistic-report`, { params: sharingCodeParams(sharingCode) });
  }

  public getRenderList(submissionId: string, sharingCode?: string) {
    return this.http.get<string[]>(this.baseUrl + `/cdss/submission/${submissionId}/render`, { params: sharingCodeParams(sharingCode) });
  }

  public getRender(submissionId: string, filename: string, sharingCode?: string) {
    return this.http.get(this.baseUrl + `/cdss/submission/${submissionId}/render/${filename}`, { responseType: 'arraybuffer', params: sharingCodeParams(sharingCode) });
  }

  public getRenderMetadata(submissionId: string, filename: string, sharingCode?: string) {
    let headers = new HttpHeaders().append('accept', 'application/x-metadata');
    return this.http.get(this.baseUrl + `/cdss/submission/${submissionId}/render/${filename}`, { responseType: 'blob', headers, params: sharingCodeParams(sharingCode) });
  }

  public listSubmissions(from?: number, count?: number, filters?: Partial<CdssSubmissionFilter>, workspaceId?: string) {
    let params = new HttpParams();
    if (from !== undefined)
      params = params.set("from", from.toFixed(0));

    if (count !== undefined)
      params = params.set("count", count.toFixed(0));

    if (workspaceId)
      params = params.set("wid", workspaceId);

    if (filters) {
      if (filters.applicationId)
        params = params.set("applicationId", filters.applicationId);
      if (filters.dateFrom)
        params = params.set("dateFrom", filters.dateFrom);
      if (filters.dateTo)
        params = params.set("dateTo", filters.dateTo);
      if (filters.state)
        params = params.set("state", filters.state);
    }
      
    return this.http.get<CdssSubmissionListItem[]>(this.baseUrl + '/cdss/submission', { params, observe: "response" })
      .pipe(map(resp => {
        let range = resp.headers.get("content-range");

        if (range) {
          let result = {
            totalCount: parseInt(range.split('/')[1]),
            data: resp.body.map(submission => {
              submission.createdAt = new Date(submission.createdAt); // convert from JSON string
              return submission;
            }),
          };

          return result;
        } else {
          return {
            data: resp.body,
            totalCount: resp.body.length,
          };
        }
      })).pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 416) {
          let res: ResultRange<CdssSubmissionListItem> = {
            totalCount: 0,
            data: [],
          };
          return of(res);
        }
        throw err;
      }));
  }

  public getSubmissionSharingCode(submissionId: string) {
    return this.http.get<CdssSharingCode>(this.baseUrl + `/cdss/submission/${submissionId}/sharing`)
      .pipe(map(resp => resp.code));
  }

  public createSubmissionSharingCode(submissionId: string, symmetricKey: string) {
    let body: CdssSymmetricKey = { symmetricKey };
    return this.http.post<CdssSharingCode>(this.baseUrl + `/cdss/submission/${submissionId}/sharing`, body)
      .pipe(map(resp => resp.code));
  }

  public revokeSubmissionSharingCode(submissionId: string) {
    return this.http.delete(this.baseUrl + `/cdss/submission/${submissionId}/sharing`);
  }

  public getSymmetricKeyFromSharingCode(submissionId: string, sharingCode: string) {
    return this.http.get<CdssSymmetricKey>(this.baseUrl + `/cdss/submission/${submissionId}/sharing/${sharingCode}/key`)
      .pipe(map(resp => resp.symmetricKey));
  }

  public createPresubmission(data: CdssPresubmissionData): Observable<string> {
    return this.http.post(this.baseUrl + '/cdss/presubmission', data, { observe: 'response' }).pipe(map(resp => {
      let location = resp.headers.get('location');
      if (location) {
        return location.split('/').pop();
      }
    }));
  }

  public abortPresubmission(id: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + '/cdss/presubmission/' + id);
  }

  public getPresubmissionStatus(id: string): Observable<CdssPresubmissionStatus> {
    return this.http.get<CdssPresubmissionStatus>(this.baseUrl + `/cdss/presubmission/${id}/status`);
  }
}

export type SubmissionState = "pending" | "running" | "done" | "error";

export interface CdssSubmissionData {
  modelId: string;
  mode: "full" | "lite";
  batchId: string;
  patientSensitiveData?: string;
  features: object;
  indicationTexts: string[];
  overrides: {[role: string]: CdssSubmissionProblem[]};
  roleAssignment?: {[role: string]: string};
  requireApproval?: boolean;
}

export interface CdssPresubmissionData {
  modelId: string;
  batchId: string;
  roleAssignment?: {[role: string]: string};
  locationHints?: CdssPresubmissionLocationHint[];
}

export interface CdssPresubmissionLocationHint {
  id: string;

  // Defined to be future-proof, but we only recognize the "Z" location
  x?: number;
  y?: number;
  z?: number;
}

export interface CdssPresubmissionStatus {
  state: "pending" | "running" | "error" | "done";
  genericMasks: DLProvidedMask[];
  backgrounds: DLProvidedMask[];
}

export interface CdssSubmissionProblem {
  tag: string;
  actualValue: any;
  expectedValue: string;
}

export interface CdssSubmissionListItem {
  id: string;
  uniqueStringId: string;
  state: SubmissionState;
  patientSensitiveData?: string;
  modelName: string;
  createdAt: Date;
  isShared: boolean;
}

export interface CdssSubmissionItem {
  uniqueStringId: string;
  modelId: string;
  patientSensitiveData?: string;
  state: SubmissionState;
  features: object;
  createdAt: Date;
  indicationTexts: string[];
  overrides: {[role: string]: CdssSubmissionProblem[]};
  workspaceId?: string;
}

export interface CdssReportPredictionData {
  outcomes: object;
  reactiveMetadata: AutoMLReactiveMetadata;
  integrityMetadata: AutoMLIntegrityMetadata;
}

export interface CdssReportData {
  predictionData: {[key: string]: CdssReportPredictionData} | {[key: string]: object};
}

export interface AutoMLReactiveMetadata {
  // [0] -> distance
  // [1] -> weight
  // [2] -> correctRatio
  [ sampleId: string ]: number[];
}

interface CdssSharingCode {
  code : string;
}

interface CdssSymmetricKey {
  symmetricKey: string;
}

export interface CdssSubmissionFilter {
  applicationId: string;
  dateFrom: string;
  dateTo: string;
  state: string;
}

export interface CdssJobStats {
	running: number;
	pending: number;
  done: number;
}
