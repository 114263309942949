import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-account-reject',
    templateUrl: './account-reject.component.html',
    styleUrls: ['./account-reject.component.css'],
    standalone: false
})
export class AccountRejectComponent {
  busy = false;
  accountDeleted = false;
  error: string;

  private uid: string;
  private code: string;

  constructor(private auth: AuthenticationService, private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.uid = params.uid;
      this.code = params.code;
    });
  }

  deleteAccount() {
    this.busy = true;
    this.auth.rejectAccount(this.uid, this.code).subscribe(() => {
      this.busy = false;
      this.accountDeleted = true;
    }, (error: HttpErrorResponse) => {
      let errorMessage = error.message;
      if (typeof error.error === 'object' && error.error.message)
        errorMessage = error.error.message;
      this.error = errorMessage;
      this.busy = false;
    });
  }

}
