import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth.service';

@Component({
    selector: 'app-deletion',
    templateUrl: './deletion.component.html',
    styleUrls: ['./deletion.component.css'],
    standalone: false
})
export class DeletionComponent implements OnInit {
  uid: string;
  code: string;
  busy = false;
  done = false;
  error = false;
  showPasswordBasedLogin = false;

  twoFactorAuthenticationNeeded = false;
  oauthProviderName: string;

  loginForm = new FormGroup({
    password: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    tfCode: new FormControl(''),
  });

  constructor(private route: ActivatedRoute, private titleService: Title, private auth: AuthenticationService,
    @Inject("Window") private window: Window) {
      
    this.titleService.setTitle($localize `Dedicaid account deletion`);
    
    this.route.queryParams.subscribe(params => {
      this.uid = params.uid;
      this.code = params.code;
    });
  }

  ngOnInit(): void {
    this.busy = true;

    this.auth.getAccountDeletionDetails(this.uid, this.code)
    .subscribe(authInfo => {
      let oauthProvider = authInfo.oauth;

      this.twoFactorAuthenticationNeeded = !!authInfo['2fa'];
      this.oauthProviderName = oauthProvider;
      this.showPasswordBasedLogin = !oauthProvider;
      this.busy = false;
    }, (err: HttpErrorResponse) => {
      this.busy = false;
      this.error = true;
      if (err.status === 404) {
        window.alert($localize `This account removal link has already been acted upon and is no longer valid.`);
      } else {
        window.alert(err.error.message || $localize `Account removal error.`);
      }
    });
  }

  doCancel() {
    this.busy = true;

    this.auth.cancelDeletion(this.uid, this.code)
    .subscribe(resp => {
      this.busy = false;
      this.done = true;

      window.alert($localize `Account deletion has been canceled. You may close the window.`);
    }, (err: HttpErrorResponse) => {
      this.busy = false;
      if (err.status === 404) {
        window.alert($localize `This account removal link has already been acted upon and is no longer valid.`);
      } else {
        window.alert(err.error.message || $localize `Operation has failed`);
      }
    });
  }

  doApprove() {
    if (!this.loginForm.hasError) {
      this.loginForm.markAllAsTouched();
      return;
    }

    let email = this.loginForm.get('email').value;
    let password = this.loginForm.get('password').value;
    let twoFactorCode = this.loginForm.get('tfCode').value;

    this.busy = true;
    this.auth.authenticate(email, password, twoFactorCode).subscribe(resp => {
      if (resp.uid != this.uid) {
        this.busy = false;
        window.alert($localize `You logged in as the wrong user. Try again.`);
      } else {
        this.doApproveWithToken();
      }
    }, err => {
      this.busy = false;
      window.alert($localize `Login failed.`);
    });
  }

  private doApproveWithToken() {

    this.auth.approveDeletion(this.code)
    .subscribe(resp => {
      this.busy = false;
      this.done = true;

      window.alert($localize `Account has been deleted.`);
    }, err => {
      this.busy = false;

      if (err.status === 404) {
        window.alert($localize `This account removal link has already been acted upon and is no longer valid.`);
      } else {
        window.alert(err.error.message || $localize `Operation has failed`);
      }
    });
  }

  doOauth() {
    // Redirect to given OAuth provider
    let postOauthUrl = encodeURIComponent(`/login/deletion-after-oauth?code=${this.code}&uid=${this.uid}`);

    this.auth.getFrontendConfig().then(config => {
      this.window.location.href = `${config.authServiceUrl}/api/v1/user/login/oauth/${this.oauthProviderName}?redirectUrl=${postOauthUrl}`;
    });
  }

}
