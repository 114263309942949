import { renderIcon as C } from "../icon.renderer.js";
const H = "tasks",
  V = ["tasks", C({
    outline: '<path d="M25 7.34C25 6.06 23.95 5.02 22.67 5.01H21.87C21.42 3.24 19.82 2 17.99 2C16.16 2 14.57 3.24 14.11 5.01H13.32C12.04 5.01 11 6.06 10.99 7.34V11.01H24.99V7.34H25ZM23 9.01H13V7.34C13 7.25 13.03 7.17 13.1 7.11C13.16 7.05 13.25 7.01 13.33 7.01H16V6.01C16 4.91 16.9 4.01 18 4.01C19.1 4.01 20 4.91 20 6.01V7.01H22.67C22.85 7.01 23 7.16 23 7.34V9.01ZM29.49 5.5C29.17 5.18 28.74 5.01 28.29 5.01H26V7.01H28V32H8V7.01H10V5.01H8C7.5 4.94 7 5.08 6.62 5.41C6.24 5.73 6.01 6.2 6 6.7V32.31C6 32.76 6.18 33.19 6.51 33.51C6.84 33.83 7.26 34 7.71 34H28.29C28.74 34 29.17 33.83 29.49 33.51C29.81 33.19 29.99 32.76 30 32.31V6.7C30 6.25 29.82 5.82 29.49 5.5ZM15.63 27L25.75 16.69C26.09 16.28 26.07 15.68 25.7 15.3C25.33 14.92 24.73 14.9 24.33 15.25L15.63 24.08L11.65 20.08C11.25 19.73 10.65 19.76 10.28 20.13C9.91 20.51 9.89 21.11 10.23 21.52L15.63 27Z"/>',
    outlineAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path d="M28 15.0367H30V32.31C29.99 32.76 29.81 33.19 29.49 33.51C29.17 33.83 28.74 34 28.29 34H7.71C7.26 34 6.84 33.83 6.51 33.51C6.18 33.19 6 32.76 6 32.31V6.7C6.01 6.2 6.24 5.73 6.62 5.41C7 5.08 7.5 4.94 8 5.01H10V7.01H8V32H28V15.0367Z"/><path d="M24.7248 15.0367H25.2563C25.4197 15.082 25.5733 15.1699 25.7 15.3C26.07 15.68 26.09 16.28 25.75 16.69L15.63 27L10.23 21.52C9.89 21.11 9.91 20.51 10.28 20.13C10.65 19.76 11.25 19.73 11.65 20.08L15.63 24.08L24.33 15.25C24.4479 15.1468 24.5832 15.0758 24.7248 15.0367Z"/><path d="M22.5534 5.01H21.87C21.42 3.24 19.82 2 17.99 2C16.16 2 14.57 3.24 14.11 5.01H13.32C12.04 5.01 11 6.06 10.99 7.34V11.01H19.103C19.1909 10.6696 19.3356 10.3411 19.5362 10.0387L20.1534 9.01H13V7.34C13 7.25 13.03 7.17 13.1 7.11C13.16 7.05 13.25 7.01 13.33 7.01H16V6.01C16 4.91 16.9 4.01 18 4.01C19.1 4.01 20 4.91 20 6.01V7.01H21.3534L22.5534 5.01Z"/>',
    outlineBadged: '<path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/><path d="M28 12.7101C28.6337 12.8987 29.305 13 30 13V32.31C29.99 32.76 29.81 33.19 29.49 33.51C29.17 33.83 28.74 34 28.29 34H7.71C7.26 34 6.84 33.83 6.51 33.51C6.18 33.19 6 32.76 6 32.31V6.7C6.01 6.2 6.24 5.73 6.62 5.41C7 5.08 7.5 4.94 8 5.01H10V7.01H8V32H28V12.7101Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.0644 5.04627C23.0219 5.35811 23 5.67649 23 6C23 7.90216 23.7587 9.62712 24.99 10.8888V11.01H10.99V7.34C11 6.06 12.04 5.01 13.32 5.01H14.11C14.57 3.24 16.16 2 17.99 2C19.82 2 21.42 3.24 21.87 5.01H22.67C22.8043 5.01105 22.9361 5.02344 23.0644 5.04627ZM13 7.34V9.01H23V7.34C23 7.16 22.85 7.01 22.67 7.01H20V6.01C20 4.91 19.1 4.01 18 4.01C16.9 4.01 16 4.91 16 6.01V7.01H13.33C13.25 7.01 13.16 7.05 13.1 7.11C13.03 7.17 13 7.25 13 7.34Z"/><path d="M25.75 16.69L15.63 27L10.23 21.52C9.89 21.11 9.91 20.51 10.28 20.13C10.65 19.76 11.25 19.73 11.65 20.08L15.63 24.08L24.33 15.25C24.73 14.9 25.33 14.92 25.7 15.3C26.07 15.68 26.09 16.28 25.75 16.69Z"/>',
    solid: '<path d="M29.49 5.5C29.17 5.18 28.74 5.01 28.29 5.01H21.87C21.42 3.23 19.82 2 18 2C16.18 2 14.58 3.24 14.13 5.01H8C7.5 4.95 7 5.09 6.62 5.41C6.24 5.73 6.01 6.2 6 6.7V32.31C6 32.76 6.18 33.19 6.51 33.51C6.84 33.83 7.26 34 7.71 34H28.29C28.74 34 29.17 33.83 29.49 33.51C29.81 33.19 29.99 32.76 30 32.31V6.7C30 6.25 29.82 5.82 29.49 5.5ZM11 7.33C11 7.24 11.03 7.16 11.1 7.1C11.16 7.04 11.25 7 11.33 7H16V6C16 5.83 16.03 5.66 16.07 5.5C16.07 5.48 16.08 5.45 16.09 5.43C16.14 5.28 16.2 5.13 16.28 5C16.63 4.41 17.26 4 18 4C18.74 4 19.38 4.4 19.72 5C19.8 5.14 19.86 5.28 19.91 5.43C19.91 5.45 19.93 5.48 19.93 5.5C19.97 5.66 20 5.83 20 6V7H24.67C24.85 7 25 7.15 25 7.33V10H11V7.33ZM25.83 16.75L15.63 27.14L10.16 21.59C9.78 21.14 9.81 20.47 10.21 20.06C10.62 19.65 11.28 19.62 11.71 20L15.62 23.93L24.25 15.17C24.69 14.79 25.35 14.81 25.76 15.22C26.17 15.63 26.19 16.3 25.82 16.74L25.83 16.75Z"/>',
    solidAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path d="M22.5534 5.01H21.87C21.42 3.23 19.82 2 18 2C16.18 2 14.58 3.24 14.13 5.01H8C7.5 4.95 7 5.09 6.62 5.41C6.24 5.73 6.01 6.2 6 6.7V32.31C6 32.76 6.18 33.19 6.51 33.51C6.84 33.83 7.26 34 7.71 34H28.29C28.74 34 29.17 33.83 29.49 33.51C29.81 33.19 29.99 32.76 30 32.31V15.0367H25.5173C25.6044 15.0851 25.6863 15.1463 25.76 15.22C26.17 15.63 26.19 16.3 25.82 16.74L25.83 16.75L15.63 27.14L10.16 21.59C9.78 21.14 9.81 20.47 10.21 20.06C10.62 19.65 11.28 19.62 11.71 20L15.62 23.93L24.25 15.17C24.3101 15.1181 24.3743 15.0737 24.4414 15.0367H22.3395C21.1577 15.0604 20.0233 14.4489 19.4206 13.3893C18.8204 12.3342 18.8703 11.0423 19.5362 10.0387L19.5594 10H11V7.33C11 7.24 11.03 7.16 11.1 7.1C11.16 7.04 11.25 7 11.33 7H16V6C16 5.83 16.03 5.66 16.07 5.5C16.07 5.48 16.08 5.45 16.09 5.43C16.14 5.28 16.2 5.13 16.28 5C16.63 4.41 17.26 4 18 4C18.74 4 19.38 4.4 19.72 5C19.8 5.14 19.86 5.28 19.91 5.43C19.91 5.44 19.915 5.4525 19.92 5.465C19.925 5.4775 19.93 5.49 19.93 5.5C19.97 5.66 20 5.83 20 6V7H21.3594L22.5534 5.01Z"/>',
    solidBadged: '<path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.0695 5.01H21.87C21.42 3.23 19.82 2 18 2C16.18 2 14.58 3.24 14.13 5.01H8C7.5 4.95 7 5.09 6.62 5.41C6.24 5.73 6.01 6.2 6 6.7V32.31C6 32.76 6.18 33.19 6.51 33.51C6.84 33.83 7.26 34 7.71 34H28.29C28.74 34 29.17 33.83 29.49 33.51C29.81 33.19 29.99 32.76 30 32.31V13C27.6213 13 25.5196 11.8135 24.2547 10H11V7.33C11 7.24 11.03 7.16 11.1 7.1C11.16 7.04 11.25 7 11.33 7H16V6C16 5.83 16.03 5.66 16.07 5.5C16.07 5.48 16.08 5.45 16.09 5.43C16.14 5.28 16.2 5.13 16.28 5C16.63 4.41 17.26 4 18 4C18.74 4 19.38 4.4 19.72 5C19.8 5.14 19.86 5.28 19.91 5.43C19.91 5.44 19.915 5.4525 19.92 5.465C19.925 5.4775 19.93 5.49 19.93 5.5C19.97 5.66 20 5.83 20 6V7H23.0709C23.0242 6.6734 23 6.33952 23 6C23 5.66394 23.0237 5.33342 23.0695 5.01ZM15.63 27.14L25.83 16.75L25.82 16.74C26.19 16.3 26.17 15.63 25.76 15.22C25.35 14.81 24.69 14.79 24.25 15.17L15.62 23.93L11.71 20C11.28 19.62 10.62 19.65 10.21 20.06C9.81 20.47 9.78 21.14 10.16 21.59L15.63 27.14Z"/>'
  })];
export { V as tasksIcon, H as tasksIconName };
