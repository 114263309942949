import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthenticationService, FeaturesConfig, UserProfile } from '../../authentication/auth.service';
import { catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SettingsAccountComponent } from '../settings-account/settings-account.component';
import { SettingsTwoFactorComponent } from '../settings-two-factor/settings-two-factor.component';
import { SettingsPasswordComponent } from '../settings-password/settings-password.component';
import { SettingsInvoicingComponent } from '../settings-invoicing/settings-invoicing.component';
import { AccountRemovalComponent } from '../account-removal/account-removal.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { SettingsSavedCardComponent } from '../settings-saved-card/settings-saved-card.component';
import { TopupCreditComponent } from '../topup-credit/topup-credit.component';
import { environment } from 'src/environments/environment';
import { SettingsInstitutionComponent } from '../settings-institution/settings-institution.component';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'main-frame-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
    standalone: false
})
export class LayoutComponent implements OnInit, OnDestroy {
  //@Input() screen: string;

  @ViewChild("settingsAccount", { static: true }) settingsAccount: SettingsAccountComponent;
  @ViewChild("settings2FA", { static: true }) settings2FA: SettingsTwoFactorComponent;
  @ViewChild("settingsPassword", { static: true }) settingsPassword: SettingsPasswordComponent;
  @ViewChild("settingsInvoicing", { static: true }) settingsInvoicing: SettingsInvoicingComponent;
  @ViewChild("accountRemoval", { static: true }) accountRemoval: AccountRemovalComponent;
  @ViewChild("messageBox", { static: true }) messageBox: MessageBoxComponent;
  @ViewChild("settingsSavedCard", { static: true }) settingsSavedCard: SettingsSavedCardComponent;
  @ViewChild("topUpCredit", { static: true }) topupCredit: TopupCreditComponent;
  @ViewChild("settingsInstitution", { static: true }) settingsInstitution: SettingsInstitutionComponent;

  @Output()
  creditChanged = new EventEmitter<void>();

  isRoot = false;
  showAdmin = false;
  env = environment;
  loading = false;

  private routerEventsSubscription: Subscription;
  features?: FeaturesConfig;
  
  constructor(public authenticationService: AuthenticationService, private router: Router, private http: HttpClient) { }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.isRoot = this.authenticationService.hasRole('root');
    this.showAdmin = this.authenticationService.hasRole('tenancy-admin');

    this.routerEventsSubscription = this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart:
          this.loading = true;
          break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          this.loading = false;
          break;
      }
    });

    this.authenticationService.getFrontendConfig().then(feConfig => {
      this.features = feConfig.features ?? environment;
    });
  }

  public openAccountSettings() {
    this.settingsAccount.openAccountSettings();
  }

  public open2FASettings() {
    this.settings2FA.open2FASettings();
  }

  public openChangePassword() {
    if (!this.authenticationService.usesOauth)
      this.settingsPassword.openChangePassword();
  }

  openInvoicingSettings() {
    this.settingsInvoicing.openInvoicingSettings();
  }

  openAccountRemoval() {
    this.accountRemoval.initiate();
  }

  onAccountRemovalInitiated(emailedTo) {
    this.messageBox.title = "Account removal";
    this.messageBox.message = `Further instructions have been sent to ${emailedTo}. Check your inbox.`;
    this.messageBox.open();
  }
  
  logout() {
    this.authenticationService.forgetToken();
    this.router.navigateByUrl("/login");
  }

  openSavedCard() {
    this.settingsSavedCard.open();
  }

  openAddCredit() {
    this.topupCredit.open();
  }

  openInstitutionSettings() {
    this.settingsInstitution.open();
  }

  public get authenticated() {
    return this.authenticationService.isAuthenticated();
  }
}
