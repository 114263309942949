import { renderIcon as C } from "../icon.renderer.js";
const H = "export",
  e = ["export", C({
    outline: '<path fill-rule="evenodd" clip-rule="evenodd" d="M6 13.61H13.61V6H24V14.38H26V6C26 4.89543 25.1046 4 24 4H10.87L4 10.87V30C4 31.1046 4.89543 32 6 32H24C25.1046 32 26 31.1046 26 30H6V13.61ZM28.32 16.35C27.9232 16.0102 27.3318 16.0331 26.9624 16.4024C26.5931 16.7718 26.5702 17.3632 26.91 17.76L30.16 21H18C17.4477 21 17 21.4477 17 22C17 22.5523 17.4477 23 18 23H30.19L26.91 26.28C26.6246 26.5244 26.5003 26.9081 26.5882 27.2734C26.6761 27.6387 26.9613 27.9239 27.3266 28.0118C27.6919 28.0997 28.0756 27.9754 28.32 27.69L34 22L28.32 16.35ZM11.69 6L6 11.69V12H12V6H11.69Z"/>',
    outlineAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.1594 4L21.9594 6H13.61V13.61H6V30H26C26 31.1046 25.1046 32 24 32H6C4.89543 32 4 31.1046 4 30V10.87L10.87 4H23.1594ZM6 11.69L11.69 6H12V12H6V11.69Z"/><path d="M26.9624 16.4024C27.3318 16.0331 27.9232 16.0102 28.32 16.35L34 22L28.32 27.69C28.0756 27.9754 27.6919 28.0997 27.3266 28.0118C26.9613 27.9239 26.6761 27.6387 26.5882 27.2734C26.5003 26.9081 26.6246 26.5244 26.91 26.28L30.19 23H18C17.4477 23 17 22.5523 17 22C17 21.4477 17.4477 21 18 21H30.16L26.91 17.76C26.5702 17.3632 26.5931 16.7718 26.9624 16.4024Z"/>',
    outlineBadged: '<path fill-rule="evenodd" clip-rule="evenodd" d="M13.61 13.61H6V30H26C26 31.1046 25.1046 32 24 32H6C4.89543 32 4 31.1046 4 30V10.87L10.87 4H22.78C22.5968 4.65097 22.5026 5.32375 22.5 6H13.61V13.61ZM11.69 6L6 11.69V12H12V6H11.69Z"/><path d="M26 12.34C25.2264 11.8501 24.5486 11.2231 24 10.49V14.38H26V12.34Z"/><path d="M26.9624 16.4024C27.3318 16.0331 27.9232 16.0102 28.32 16.35L34 22L28.32 27.69C28.0756 27.9754 27.6919 28.0997 27.3266 28.0118C26.9613 27.9239 26.6761 27.6387 26.5882 27.2734C26.5003 26.9081 26.6246 26.5244 26.91 26.28L30.19 23H18C17.4477 23 17 22.5523 17 22C17 21.4477 17.4477 21 18 21H30.16L26.91 17.76C26.5702 17.3632 26.5931 16.7718 26.9624 16.4024Z"/><path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/>',
    solid: '<path fill-rule="evenodd" clip-rule="evenodd" d="M16 22C16 21.45 16.45 21 17 21H25V23H17C16.45 23 16 22.55 16 22ZM25 23H30.19L26.91 26.28C26.62 26.52 26.5 26.91 26.59 27.27C26.68 27.64 26.96 27.92 27.33 28.01C27.7 28.1 28.08 27.97 28.32 27.69L34 22L28.32 16.35C27.92 16.01 27.33 16.03 26.96 16.4C26.59 16.77 26.57 17.36 26.91 17.76L30.16 21H25V6C25 4.9 24.1 4 23 4H10.87L4 10.86V30C4 31.1 4.9 32 6 32H23C24.1 32 25 31.1 25 30V23ZM12 12H6V11.68L11.69 6H12V12Z"/>',
    solidAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.1557 4.00605L19.5362 10.0387C18.8703 11.0423 18.8204 12.3342 19.4206 13.3893C20.0233 14.4489 21.1577 15.0604 22.3395 15.0367H25V21H17C16.45 21 16 21.45 16 22C16 22.55 16.45 23 17 23H25V30C25 31.1 24.1 32 23 32H6C4.9 32 4 31.1 4 30V10.86L10.87 4H23C23.0524 4 23.1043 4.00204 23.1557 4.00605ZM6 12H12V6H11.69L6 11.68V12Z"/><path d="M25 23H30.19L26.91 26.28C26.62 26.52 26.5 26.91 26.59 27.27C26.68 27.64 26.96 27.92 27.33 28.01C27.7 28.1 28.08 27.97 28.32 27.69L34 22L28.32 16.35C27.92 16.01 27.33 16.03 26.96 16.4C26.59 16.77 26.57 17.36 26.91 17.76L30.16 21H25V23Z"/>',
    solidBadged: '<path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.2839 4.02023C23.0991 4.64799 23 5.31242 23 6C23 7.90731 23.7628 9.63645 25 10.899V21H17C16.45 21 16 21.45 16 22C16 22.55 16.45 23 17 23H25V30C25 31.1 24.1 32 23 32H6C4.9 32 4 31.1 4 30V10.86L10.87 4H23C23.0963 4 23.1911 4.0069 23.2839 4.02023ZM6 12H12V6H11.69L6 11.68V12Z"/><path d="M25 23H30.19L26.91 26.28C26.62 26.52 26.5 26.91 26.59 27.27C26.68 27.64 26.96 27.92 27.33 28.01C27.7 28.1 28.08 27.97 28.32 27.69L34 22L28.32 16.35C27.92 16.01 27.33 16.03 26.96 16.4C26.59 16.77 26.57 17.36 26.91 17.76L30.16 21H25V23Z"/>'
  })];
export { e as exportIcon, H as exportIconName };
