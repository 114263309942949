<app-login></app-login>

<clr-wizard #wizard [clrWizardOpen]="true"
  [clrWizardClosable]="false"
  clrWizardSize="lg"
  [clrWizardPreventDefaultNext]="true"
  (clrWizardOnNext)="wizard.forceNext()"
  (clrWizardOnFinish)="onFinish()">

  <clr-wizard-title i18n>New User Wizard</clr-wizard-title>

  <clr-wizard-button [type]="'previous'" i18n>Back</clr-wizard-button>
  <clr-wizard-button [type]="'next'" i18n>Next</clr-wizard-button>
  <clr-wizard-button [type]="'finish'" i18n>Create account</clr-wizard-button>

  <clr-wizard-page [clrWizardPageNextDisabled]="busy || hasError">
    <ng-template clrPageTitle i18n>Introduction</ng-template>

    <div class="center" *ngIf="busy">
        <span class="spinner spinner-inline">Loading...</span>
        &nbsp;
        <span>Loading...</span>
    </div>

    <div *ngIf="hasError">
        <clr-alert clrAlertType="danger" [clrAlertClosable]="false">
            <clr-alert-item>
              <span class="alert-text" i18n>The invitation link you followed doesn't seem to be valid.</span>
            </clr-alert-item>
          </clr-alert>
    </div>
    <div *ngIf="!hasError && !busy">
        <p i18n>
            This invitation link allows you to create a new user account on the Dedicaid platform.
        </p>
    </div>
  </clr-wizard-page>

  <clr-wizard-page>
    <ng-template clrPageTitle i18n>Account Type</ng-template>
    <p i18n>
        You can either create a local account or a 3rd party account.
    </p>
    <p i18n>
        <b>Local account</b> is specific to the Dedicaid platform and requires you to create a password.
    </p>
    <p i18n>
        <b>3rd party account</b> enables you to authenticate with an existing account created at Google, Microsoft and others.
    </p>

    <form clrForm clrLayout="vertical">
        <clr-radio-container>
            <label i18n>Account type</label>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="options" required value="local" [(ngModel)]="accountType" />
              <label i18n>Local account</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
              <input type="radio" clrRadio name="options" required value="oauth" [(ngModel)]="accountType" />
              <label i18n>3rd party account</label>
            </clr-radio-wrapper>
          </clr-radio-container>          
    </form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="accountType === 'oauth' || busy">
    <ng-template clrPageTitle i18n>Finish</ng-template>
    <div *ngIf="accountType === 'oauth'">
        <p i18n>
            Finish registration by choosing one of the below 3rd party authentication providers.
            Remember to always use the same provider to authenticate in future.
        </p>

        <p class="social-signup">
          <button *ngFor="let provider of socialLoginProviders" [ngClass]="['social-button']" [style.background-color]="provider.bgcolor" (click)="redirectToSocialLogin(provider)">
            <div class="social-icon">
                <img [src]="'data:image/svg+xml;base64,' + provider.icon" width="26" height="26" />
            </div>
            <div class="social-label" i18n>
                Sign up with {{ provider.name }}
            </div>
        </button>
      </p>
    </div>
    <div *ngIf="accountType === 'local'">
        <app-password-signup></app-password-signup>
    </div>
  </clr-wizard-page>
</clr-wizard>
