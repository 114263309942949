import { Subject, Subscription } from "rxjs";
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from "rxjs/webSocket";
import { CdssReportEvent } from "./events";

export class CdssReportUpdates {
    private keepaliveIntervalId: number;
    private config: WebSocketSubjectConfig<CdssReportEvent>;
    private wsSubject: WebSocketSubject<CdssReportEvent>;
    private subject = new Subject<CdssReportEvent>();
    private subscription: Subscription;
      
    constructor(baseUrl: string, tokenProvider: TokenProvider, workspaceId: string|null, private reconnectDelay = 0) {
        class AutoTokenWebSocket extends WebSocket {
            constructor (url: string, protocols?: string|string[]) {
                super(url + '?access_token=' + encodeURIComponent(tokenProvider.getToken()), protocols);
            }
        }
        
        let url = "";
    
        if (!baseUrl.startsWith('http')) {
            if (window.location.protocol === "https:")
                url = "wss:";
            else
                url = "ws:";
            url += `//${window.location.hostname}:${window.location.port}`;
     }
    
        url += baseUrl + '/cdss/submission/events';

        if (workspaceId)
            url += '/' + workspaceId;
    
        this.config = {
            url: url,
            WebSocketCtor: AutoTokenWebSocket,
            deserializer: (e: MessageEvent) => {
                console.debug("WS report event: " + e.data);
        
                const parsed = JSON.parse(e.data);
        
                return parsed;
            }
        };
    
        this.connectWS();
    
        this.keepaliveIntervalId = setInterval(() => {
            try {
                this.wsSubject?.next({ event: "keepalive" } as unknown as CdssReportEvent);
            } catch (err) {}
        }, 15*1000) as any;
    }

    public destroy() {
        clearInterval(this.keepaliveIntervalId);

        this.subscription?.unsubscribe();
    }

    private connectWS() {
        this.wsSubject = webSocket(this.config);
        
        // We do it like this instead of using pipe(retry()) due to WS connections
        // sometimes being closed with a .complete() call, in which case retry() doesn't resubscribe.
        this.subscription = this.wsSubject.subscribe({ next: msg => {
                this.subject.next(msg);
            },
            error: err => {
                console.error("CDSS ReportUpdates: WS connection error", err);
                this.subscription = undefined;
                this.wsSubject = undefined;
                this.subject.next(null);

                setTimeout(() => this.connectWS(), this.reconnectDelay);
            },
            complete: () => {
                console.warn("CDSS ReportUpdates: WS connection close");
                this.subscription = undefined;
                this.wsSubject = undefined;
                this.subject.next(null);

                setTimeout(() => this.connectWS(), this.reconnectDelay);
            }
        });
    }

    updates() {
        return this.subject.asObservable();
    }
}

export interface TokenProvider {
    getToken(): string;
}
