import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appReorderableItem]',
    standalone: false
})
export class ReorderableItemDirective {
  @Input()
  appReorderableItem: any;

  constructor(public element: ElementRef) {
  }

}
