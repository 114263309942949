import { Directive, HostListener } from '@angular/core';
import { ReorderableItemDirective } from './reorderable-item.directive';
import { ReorderableListDirective } from './reorderable-list.directive';

@Directive({
    selector: '[appReorderableHandle]',
    standalone: false
})
export class ReorderableHandleDirective {

  constructor(public parentList: ReorderableListDirective, public item: ReorderableItemDirective) {
  }

  @HostListener('mousedown', ['$event'])
  roleMoveMouseDown(event: MouseEvent) {
    this.parentList.roleMoveMouseDown(this.item, event);
  }
}
