import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { from, map, Observable, of, switchMap, tap } from 'rxjs';

export const AUTH_URL = new InjectionToken("AUTH_URL");

@Injectable({
  providedIn: 'root'
})
export class WorkspaceBackendService {

  private readonly cache = new Map<string, Workspace>();

  constructor(private http: HttpClient, @Inject(AUTH_URL) private authUrl: AuthServiceUrlProvider) { }

  public listWorkspaces(kind: string, scopeLevel?: string, scopeId?: string): Observable<WorkspaceListItem[]> {
    //return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
    let params = new HttpParams();

    if (scopeLevel) {
      params = params.set('scopeLevel', scopeLevel);
      if (scopeId)
        params = params.set('scopeId', scopeId);
    }

    return from(this.authUrl.getAuthServiceUrl()).pipe(switchMap(authServiceUrl => {
      return this.http.get<WorkspaceListItem[]>(authServiceUrl + `/api/v1/workspace/${kind}`, { params });
    }));
  }

  public getWorkspace(kind: string, id: string): Observable<Workspace> {
    const key = `${kind}/${id}`;
    if (this.cache.has(key)) {
      return of(this.cache.get(key));
    }

    //return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
    return from(this.authUrl.getAuthServiceUrl()).pipe(switchMap(authServiceUrl => {
      return this.http.get<Workspace>(authServiceUrl + `/api/v1/workspace/${kind}/${id}`).pipe(tap(workspace => {
        this.cache.set(key, workspace);
      }))
    }));
  }

  public createWorkspace(kind: string, data: WorkspaceOptions) {
    return from(this.authUrl.getAuthServiceUrl()).pipe(switchMap(authServiceUrl => {
      return this.http.post<void>(`${authServiceUrl}/api/v1/workspace/${kind}`, data, { observe: 'response' });
    }), map(resp => {
      return resp.headers.get('location').split('/').pop();
    }));
  }

  public modifyWorkspace(kind: string, id: string, data: Partial<WorkspaceOptions>) {
    return from(this.authUrl.getAuthServiceUrl()).pipe(switchMap(authServiceUrl => {
      return this.http.put<void>(`${authServiceUrl}/api/v1/workspace/${kind}/${id}`, data);
    }));
  }

  public deleteWorkspace(kind: string, id: string) {
    return from(this.authUrl.getAuthServiceUrl()).pipe(switchMap(authServiceUrl => {
      return this.http.delete<void>(`${authServiceUrl}/api/v1/workspace/${kind}/${id}`);
    }));
  }
}

export interface AuthServiceUrlProvider {
  getAuthServiceUrl(): Promise<string>;
}

export interface WorkspaceListItem {
  id: string;
  name: string;
}

export interface Workspace {
  name: string;
  publicKey: string;
  privateKey: string;
}

export interface WorkspaceOptions {
  name: string;
  scopeId?: string;
  scopeLevel: "global" | "tenant";
  accessMode: null | "group";
  groupId?: string;
}
