import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class NeedAuthGuard  {
	constructor(private authenticationService: AuthenticationService, private router: Router) {
	}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
	  // TODO: Replace with https://stackoverflow.com/a/53429547/479753
	  const redirectUrl = route['_routerState']['url'];
  
	  if (this.authenticationService.isAuthenticated())
		return true;
  
	  this.router.navigateByUrl(
		this.router.createUrlTree(
		  ['/login'], {
			queryParams: {
			  redirectUrl
			}
		  }
		)
	  );
  
	  return false;
	}
  }
  