<nav class="subnav">
    <span>
        <cds-icon shape="tree-view"></cds-icon>
        <label [routerLink]="'/workspaces/select/' + workspaceKind()" class="ws-label" i18n>Workspace:</label>
    </span>

    <clr-combobox
        [ngModel]="workspace()"
        (ngModelChange)="onWorkspaceChange($event)"
        required
        [clrLoading]="loading"
        (clrInputChange)="fetchWorkspaces($event)"
        (clrOpenChange)="$event ? fetchWorkspaces() : null"
    >
    <ng-container *clrOptionSelected="let selected">
        <cds-icon [attr.shape]="selected.type === 'workspace' ? 'users' : 'user'"></cds-icon>
        {{ selected?.name }}
    </ng-container>
    <clr-options>
    <clr-option
        *clrOptionItems="let workspace of $any(asyncWorkspaces | async); field: 'name'"
        [clrValue]="workspace">
        <cds-icon [attr.shape]="workspace.type === 'workspace' ? 'users' : 'user'"></cds-icon>
        {{ workspace.name }}
    </clr-option>
    </clr-options>
</clr-combobox>

</nav>
