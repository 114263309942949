import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutComponent } from '../main-frame/layout/layout.component';

@Component({
    selector: 'app-first-login',
    templateUrl: './first-login.component.html',
    styleUrls: ['./first-login.component.css'],
    standalone: false
})
export class FirstLoginComponent {
  @ViewChild("layout", {static: true}) layoutComponent: LayoutComponent;

  constructor() { }
}
