import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/auth.service';

@Component({
    selector: 'app-account-removal',
    templateUrl: './account-removal.component.html',
    styleUrls: ['./account-removal.component.css'],
    standalone: false
})
export class AccountRemovalComponent {
  opened = false;
  busy = false;

  @Output() emailedTo = new EventEmitter<string>();

  constructor(private auth: AuthenticationService) { }

  public initiate() {
    this.opened = true;
  }

  doDelete() {
    this.busy = true;

    this.auth.requestAccountDeletion().subscribe(resp => {
      this.busy = false;
      this.opened = false;

      let emailedTo = resp['emailedTo'];
      this.emailedTo.emit(emailedTo);
    }, err => {
      this.busy = false;
      window.alert("Operation has failed.");
    });
  }

}
