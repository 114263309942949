import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService, InvoicingSettings } from '../../authentication/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-settings-invoicing',
    templateUrl: './settings-invoicing.component.html',
    styleUrls: ['./settings-invoicing.component.css'],
    standalone: false
})
export class SettingsInvoicingComponent implements OnInit {
  countries: Country[];
  showModal = false;

  busy: number = 0;
  invoicingForm = new FormGroup({
    companyName: new FormControl(''),
    companyID: new FormControl(''),
    vatID: new FormControl(''),
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(''),
    city: new FormControl(''),
    postalCode: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    //billingPeriod: new FormControl(''),
  });

  constructor(private authenticationService: AuthenticationService, private http: HttpClient, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.http.get<Country[]>("/assets/countries.json").subscribe(list => this.countries = list);
  }

  public openInvoicingSettings() {
    this.authenticationService.getInvoicingSettings(this.authenticationService.uid)
    .subscribe((res: InvoicingSettings) => {

      this.invoicingForm.get('companyName').setValue(res.companyName);
      this.invoicingForm.get('companyID').setValue(res.companyID);
      this.invoicingForm.get('vatID').setValue(res.vatID);
      this.invoicingForm.get('addressLine1').setValue(res.addressLine1);
      this.invoicingForm.get('addressLine2').setValue(res.addressLine2);
      this.invoicingForm.get('city').setValue(res.city);
      this.invoicingForm.get('postalCode').setValue(res.zip);
      this.invoicingForm.get('state').setValue(res.state);
      this.invoicingForm.get('country').setValue(res.countryCode);
      // this.invoicingForm.get('billingPeriod').setValue(res.billingPeriod);
      this.showModal = true;
      this.changeDetectorRef.markForCheck();
    }, err => {
      window.alert("Error getting invoicing data!");
    });
  }

  saveInvoicingSettings() {
    this.busy++;

    let settings = new InvoicingSettings();
    settings.companyName = this.invoicingForm.get('companyName').value || undefined;
    settings.companyID = this.invoicingForm.get('companyID').value || undefined;
    settings.vatID = this.invoicingForm.get('vatID').value || undefined;
    settings.addressLine1 = this.invoicingForm.get('addressLine1').value || undefined;
    settings.addressLine2 = this.invoicingForm.get('addressLine2').value || undefined;
    settings.zip = this.invoicingForm.get('postalCode').value || undefined;
    settings.city = this.invoicingForm.get('city').value || undefined;
    settings.state = this.invoicingForm.get('state').value || undefined;
    settings.countryCode = this.invoicingForm.get('country').value || undefined;
    // settings.billingPeriod = this.invoicingForm.get('billingPeriod').value || undefined;

    this.authenticationService.setInvoicingSettings(this.authenticationService.uid, settings)
    .subscribe(res => {
      this.busy--;
      this.showModal = false;
      this.changeDetectorRef.markForCheck();
    }, err => {
      window.alert($localize `Error saving invoicing data!`);
    });
  }

}

interface Country {
  name: string;
  code: string;
}
