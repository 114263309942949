import { renderIcon as C } from "../icon.renderer.js";
const H = "import",
  d = ["import", C({
    outline: '<path fill-rule="evenodd" clip-rule="evenodd" d="M28 4H14.87L8 10.86V15H10V13.61H17.61V6H28V30H8C8 31.1046 8.89543 32 10 32H28C29.1046 32 30 31.1046 30 30V6C30 4.89543 29.1046 4 28 4ZM16 12H10V11.68L15.7 6H16V12Z"/><path d="M11.6182 27.2734C11.5303 26.9081 11.6546 26.5244 11.94 26.28L15.23 23H3C2.44772 23 2 22.5523 2 22C2 21.4477 2.44772 21 3 21H15.2L11.91 17.73C11.5702 17.3332 11.5931 16.7418 11.9624 16.3724C12.3318 16.0031 12.9232 15.9802 13.32 16.32L19 22L13.35 27.69C13.1056 27.9754 12.7219 28.0997 12.3566 28.0118C11.9913 27.9239 11.7061 27.6387 11.6182 27.2734Z"/>',
    outlineAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.1594 4H14.87L8 10.86V15H10V13.61H17.61V6H21.9594L23.1594 4ZM10 12H16V6H15.7L10 11.68V12Z"/><path d="M28 15.0367H30V30C30 31.1046 29.1046 32 28 32H10C8.89543 32 8 31.1046 8 30H28V15.0367Z"/><path d="M11.94 26.28C11.6546 26.5244 11.5303 26.9081 11.6182 27.2734C11.7061 27.6387 11.9913 27.9239 12.3566 28.0118C12.7219 28.0997 13.1056 27.9754 13.35 27.69L19 22L13.32 16.32C12.9232 15.9802 12.3318 16.0031 11.9624 16.3724C11.5931 16.7418 11.5702 17.3332 11.91 17.73L15.2 21H3C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H15.23L11.94 26.28Z"/>',
    outlineBadged: '<path fill-rule="evenodd" clip-rule="evenodd" d="M17.61 13.61H10V15H8V10.86L14.87 4H22.78C22.5968 4.65097 22.5026 5.32375 22.5 6H17.61V13.61ZM15.7 6L10 11.69V12H16V6H15.7Z"/><path d="M28 13.22V30H8C8 31.1046 8.89543 32 10 32H28C29.1046 32 30 31.1046 30 30V13.5C29.3237 13.4974 28.651 13.4032 28 13.22Z"/><path d="M11.6182 27.2734C11.5303 26.9081 11.6546 26.5244 11.94 26.28L15.23 23H3C2.44772 23 2 22.5523 2 22C2 21.4477 2.44772 21 3 21H15.2L11.91 17.73C11.5702 17.3332 11.5931 16.7418 11.9624 16.3724C12.3318 16.0031 12.9232 15.9802 13.32 16.32L19 22L13.35 27.69C13.1056 27.9754 12.7219 28.0997 12.3566 28.0118C11.9913 27.9239 11.7061 27.6387 11.6182 27.2734Z"/><path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/>',
    solid: '<path fill-rule="evenodd" clip-rule="evenodd" d="M28 4H14.87L8 10.86V21H3C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H8V30C8 31.1046 8.89543 32 10 32H28C29.1046 32 30 31.1046 30 30V6C30 4.89543 29.1046 4 28 4ZM8 23H15.23L11.91 26.27C11.5702 26.6668 11.5931 27.2582 11.9624 27.6276C12.3318 27.9969 12.9232 28.0198 13.32 27.68L19 22L13.36 16.34C12.9632 16.0002 12.3718 16.0231 12.0024 16.3924C11.6331 16.7618 11.6102 17.3532 11.95 17.75L15.2 21H8V23ZM16 12H10V11.68L15.69 6H16V12Z"/>',
    solidAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.1594 4L19.5362 10.0387C18.8703 11.0423 18.8204 12.3342 19.4206 13.3893C20.0233 14.4489 21.1577 15.0604 22.3395 15.0367H30V30C30 31.1046 29.1046 32 28 32H10C8.89543 32 8 31.1046 8 30V23H15.23L11.91 26.27C11.5702 26.6668 11.5931 27.2582 11.9624 27.6276C12.3318 27.9969 12.9232 28.0198 13.32 27.68L19 22L13.36 16.34C12.9632 16.0002 12.3718 16.0231 12.0024 16.3924C11.6331 16.7618 11.6102 17.3532 11.95 17.75L15.2 21H8V10.86L14.87 4H23.1594ZM10 12H16V6H15.69L10 11.68V12Z"/><path d="M8 21H3C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H8V21Z"/>',
    solidBadged: '<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 6C22.5026 5.32375 22.5968 4.65097 22.78 4H14.87L8 10.86V21H3C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H8V30C8 31.1046 8.89543 32 10 32H28C29.1046 32 30 31.1046 30 30V13.5C25.8579 13.5 22.5 10.1421 22.5 6ZM8 23H15.23L11.91 26.27C11.5702 26.6668 11.5931 27.2582 11.9624 27.6276C12.3318 27.9969 12.9232 28.0198 13.32 27.68L19 22L13.36 16.34C12.9632 16.0002 12.3718 16.0231 12.0024 16.3924C11.6331 16.7618 11.6102 17.3532 11.95 17.75L15.2 21H8V23ZM16 12H10V11.68L15.69 6H16V12Z"/><path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/>'
  })];
export { d as importIcon, H as importIconName };
