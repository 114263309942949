import { RadiomicsDelineation, RadiomicsDichotomization, RadiomicsFeatureParameter, RadiomicsRoleVariant } from "./radiomics-common";

export interface GenericFileSaver {
	writeFile(path: string, data: string);
}
  
export class RadiomicsConfig {
	public series: RadiomicsSerie[] = [];
	public backgrounds: RadiomicsBackground[] = [];
	public delineation: RadiomicsDelineation = {
		method: "VOIs",
		multiLesion: null,
	};
	public dichotomization: RadiomicsDichotomization = {
		method: "Binary",
		binaryCutoff: 0.5,
	};

	constructor(private filesystem: GenericFileSaver) {}

  saveRoleVariants(values: RadiomicsRoleVariant[], usedFeatures: RadiomicsFeatureParameter[]) {
	let data = "Key;Role;ResolutonX;ResolutionY;ResolutionZ;BinWidth\n";

	let usedRoleVariants = new Set<string>();
	for (let ft of usedFeatures) {
		for (let variant of ft.roleVariants)
			usedRoleVariants.add(variant);
	}
  
	values.forEach(row => {
	  if (!usedRoleVariants.has(row.key)) {
		console.debug(`Role variant ${row.key} is not used for any features, skipping...`);
		return;
	  }
	  let line = csvEscape(row.key) + ';' + csvEscape(row.role) + ';';
  
	  line += row.resolutionX.toString() + ';';
	  line += row.resolutionY.toString() + ';';
	  line += row.resolutionZ.toString() + ';';
	  line += row.binWidth.toString();
  
	  data += `${line}\n`;
	});

	console.log("RoleVariants.csv:\n" + data);
  
	this.filesystem.writeFile('RoleVariants.csv', data);
  }
  
  saveFeatureParameters(values: RadiomicsFeatureParameter[]) {
	let data = "Key;RoleVariant(s);Feature\n";
  
	values.forEach((row, index) => {
	  let line = `${index+1};`;
	  line += csvEscape(row.roleVariants.join(',')) + ';';
	  line += csvEscape(row.feature) + "\n";
  
	  data += line;
	});

	console.log("FeatureParameters.csv:\n" + data);
  
	this.filesystem.writeFile('FeatureParameters.csv', data);
  }
  
  private saveIni(data: IniFileData, filename: string) {
	let contents = "";
  
	data.forEach((entries, groupName) => {
	  contents += `[${iniEscape(groupName)}]\n`;
  
	  entries.forEach((value, key) => {
		contents += `${iniEscape(key)}=${iniEscape(value)}\n`;
	  });
  
	  contents += `\n`;
	});

	console.log(filename+":\n" + contents);
  
	this.filesystem.writeFile(filename, contents);
  }
  
  saveSettingsIni() {
	let data: IniFileData = new Map<string, Map<string,string|number>>();
  
	let imageRoles = new Map<string,string>();
	this.series.forEach(serie => {
	  imageRoles.set(`Series/${serie.roleName}`, serie.name);
	});
	
	data.set("ImageRoles", imageRoles);
  
	let normalizationMap = new Map<string,string>();
	this.backgrounds.forEach(bck => {
	  normalizationMap.set(`Background/${bck.roleName}`, bck.name);
	  normalizationMap.set(`NormalizeBy/${bck.roleName}`, bck.normalizesSeries.join(','));
	});
	this.series.forEach(serie => {
		if (serie.normalizeLinear !== undefined) {
			normalizationMap.set(`NormalizeLinear/${serie.roleName}`, serie.normalizeLinear.toString());
		}
	});
	data.set("Normalization", normalizationMap);
  
	let delineationMap = new Map<string,string>([['Method', this.delineation.method]]);
	if (this.delineation.multiLesion) {
	  delineationMap.set("MultiLesion", this.delineation.multiLesion);

	  if (this.delineation.lowThreshold != undefined)
	  	delineationMap.set("LowThreshold", this.delineation.lowThreshold.toString());
	}
  
	data.set("Delineation", delineationMap);
  
	let radiomicsParams = new Map<string, string|number>();
	radiomicsParams.set("Method", this.dichotomization.method);
  
	if (this.dichotomization.method === "Binary")
	  radiomicsParams.set("Binary/Cutoff", this.dichotomization.binaryCutoff);
  
	data.set("Radiomics", radiomicsParams);
  
	this.saveIni(data, "settings.ini");
  }
}


type IniFileData = Map<string, Map<string,string|number>>;

function csvEscape(value: string) {
	let needsEscaping = value.includes('"') || value.includes(';');
  
	if (!needsEscaping)
		return value;
  
	return '"' + value.replace('"', '""') + '"';
}

function iniEscape(value: string|number): string {
	if (typeof value === 'number')
		return value.toString();
	
	return value
	  .replace("\\", "\\\\")
	  .replace("=", "\\=")
	  .replace(';', "\\;")
	  .replace('#', "\\#")
	  .replace('"', "\\\"")
	  .replace("'", "\\'");
}

interface RadiomicsSerie {
	name: string; // filename
	roleName: string;
	normalizeLinear?: number;
}

interface RadiomicsBackground {
	name: string;
	roleName: string;
	normalizesSeries: string[];
}
