import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScopeBarComponent } from './scope-bar/scope-bar.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { buildingIcon, ClarityIcons, treeViewIcon, usersIcon } from '@cds/core/icon';
import { AuthenticationModule } from '../authentication/authentication.module';
import { WorkspaceBarComponent } from './workspace-bar/workspace-bar.component';
import { RouterModule } from '@angular/router';

ClarityIcons.addIcons(buildingIcon, treeViewIcon, usersIcon);


@NgModule({
  declarations: [
    ScopeBarComponent,
    WorkspaceBarComponent,
  ],
  exports: [
    ScopeBarComponent,
    WorkspaceBarComponent,
  ],
  imports: [
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AuthenticationModule,
    RouterModule,
  ]
})
export class NavigationCommonModule { }
