import { renderIcon as L } from "../icon.renderer.js";
const C = "envelope",
  e = ["envelope", L({
    outline: '<path fill-rule="evenodd" clip-rule="evenodd" d="M32 6H4C2.89543 6 2 6.89543 2 8V28C2 29.1046 2.89543 30 4 30H32C33.1046 30 34 29.1046 34 28V8C34 6.89543 33.1046 6 32 6ZM30.46 28H5.66L12.66 20.76L11.22 19.37L4 26.84V9.52L16.43 21.89C17.2101 22.6654 18.4699 22.6654 19.25 21.89L32 9.21V26.71L24.64 19.35L23.23 20.76L30.46 28ZM30.38 8H5.31L17.84 20.47L30.38 8Z"/>',
    outlineAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path d="M21.9594 6H4C2.89543 6 2 6.89543 2 8V28C2 29.1046 2.89543 30 4 30H32C33.1046 30 34 29.1046 34 28V15.0263C33.8886 15.0354 33.7763 15.0389 33.6637 15.0367H32V26.71L24.64 19.35L23.23 20.76L30.46 28H5.66L12.66 20.76L11.22 19.37L4 26.84V9.52L16.43 21.89C17.2101 22.6654 18.4699 22.6654 19.25 21.89L26.1412 15.0367H23.3038L17.84 20.47L5.31 8H20.7594L21.9594 6Z"/>',
    outlineBadged: '<path d="M32 13.08V26.71L24.64 19.35L23.23 20.76L30.46 28H5.66L12.66 20.76L11.22 19.37L4 26.84V9.52L16.43 21.89C17.2101 22.6654 18.4699 22.6654 19.25 21.89L28.08 13.11C27.3753 12.9261 26.7015 12.6397 26.08 12.26L17.82 20.47L5.31 8H22.81C22.6167 7.35043 22.5124 6.67762 22.5 6H4C2.89543 6 2 6.89543 2 8V28C2 29.1046 2.89543 30 4 30H32C33.1046 30 34 29.1046 34 28V12.2C33.3802 12.59 32.7063 12.8866 32 13.08Z"/><path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/>',
    solid: '<path d="M31.9199 6C32.0562 5.98596 32.1936 5.98596 32.3299 6L17.8399 20.4637L3.38993 6.07997C3.56221 6.02966 3.74048 6.00276 3.91993 6H31.9199Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M19.2499 21.883L33.8099 7.38937C33.8815 7.58512 33.922 7.79085 33.9299 7.9991V27.9903C33.9299 29.0944 33.0345 29.9895 31.9299 29.9895H3.92993C2.82536 29.9895 1.92993 29.0944 1.92993 27.9903V7.9991C1.93205 7.83022 1.95557 7.66229 1.99993 7.49932L16.4299 21.883C17.21 22.6581 18.4699 22.6581 19.2499 21.883ZM3.90991 27.9904H5.29991L12.5899 20.7635L11.1799 19.3541L3.90991 26.561V27.9904ZM31.9099 27.9904H30.5099L23.2199 20.7635L24.6299 19.3541L31.8999 26.561L31.9099 27.9904Z"/>',
    solidAlerted: '<path d="M26.9039 1.64621L21.2222 11.1159C20.9526 11.4984 20.9281 11.9949 21.1588 12.4005C21.3896 12.806 21.8363 13.0519 22.3148 13.0367H33.6881C34.1666 13.0519 34.6134 12.806 34.8441 12.4005C35.0748 11.9949 35.0503 11.4984 34.7808 11.1159L29.0991 1.64621C28.8711 1.26913 28.4532 1.03735 28.0015 1.03735C27.5497 1.03735 27.1319 1.26913 26.9039 1.64621Z"/><path d="M21.9593 6.00006H3.91993C3.74048 6.00283 3.56221 6.02973 3.38993 6.08006L17.8399 20.4701L23.2769 15.0407H22.3395C21.1577 15.0643 20.0232 14.4526 19.4205 13.3926C18.8203 12.337 18.8703 11.0445 19.5361 10.0405L21.9593 6.00006Z"/><path d="M33.6882 13.0398C33.6882 13.0398 33.6882 13.0398 33.6882 13.0398V13.0398Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M26.1277 15.0407H33.6636C33.7527 15.0424 33.8416 15.0406 33.9299 15.0353V28.0001C33.9299 29.1046 33.0345 30.0001 31.9299 30.0001H3.92993C2.82536 30.0001 1.92993 29.1046 1.92993 28.0001V8.00004C1.93205 7.83109 1.95557 7.66308 1.99993 7.50004L16.4299 21.8901C17.21 22.6655 18.4699 22.6655 19.2499 21.8901L26.1277 15.0407ZM5.29991 28.0001H3.90991V26.5701L11.1799 19.3601L12.5899 20.7701L5.29991 28.0001ZM24.6299 19.3601L23.2199 20.7701L30.5099 28.0001H31.9099L31.8999 26.5701L24.6299 19.3601Z"/>',
    solidBadged: '<path d="M22.4999 6C22.4995 8.57439 23.8212 10.9686 25.9999 12.34L17.8399 20.47L3.38993 6.08C3.56221 6.02967 3.74048 6.00277 3.91993 6H22.4999Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M27.9999 13.21C28.6504 13.3966 29.3232 13.4941 29.9999 13.5C31.3877 13.5033 32.7489 13.1188 33.9299 12.39V28C33.9299 29.1046 33.0345 30 31.9299 30H3.92993C2.82536 30 1.92993 29.1046 1.92993 28V8C1.93205 7.83105 1.95557 7.66304 1.99993 7.5L16.4699 21.89C17.25 22.6654 18.5099 22.6654 19.2899 21.89L27.9999 13.21ZM3.90993 28H5.29993L12.5899 20.77L11.1799 19.36L3.90993 26.57V28ZM31.9099 28H30.5099L23.2199 20.77L24.6299 19.36L31.8999 26.57L31.9099 28Z"/><path d="M30 11C32.7614 11 35 8.76142 35 6C35 3.23858 32.7614 1 30 1C27.2386 1 25 3.23858 25 6C25 8.76142 27.2386 11 30 11Z"/>'
  })];
export { e as envelopeIcon, C as envelopeIconName };
