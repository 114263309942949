import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyAmount',
    standalone: false
})
export class CurrencyAmountPipe implements PipeTransform {

  transform(value: number): unknown {
    return (value / 100).toLocaleString();
  }

}
