<clr-main-container>
    <clr-header>
		<div class="branding">
			<span class="title">
				<a routerLink="/dashboard"><img class="the-logo" width="200" alt="Dedicaid" /></a>
			</span>
		</div>
		
		<div class="header-nav" *ngIf="authenticated" [clr-nav-level]="1">
			<a *ngIf="features?.enableAutoml" routerLink="/automl" class="nav-link nav-text" routerLinkActive="active" i18n>AutoML</a>
			<a *ngIf="features?.enableRadiomics" routerLink="/radiomics" class="nav-link nav-text" routerLinkActive="active" i18n>Radiomics</a>
			<a *ngIf="features?.enableCdss" routerLink="/cdss" class="nav-link nav-text" routerLinkActive="active" i18n>CDSS</a>
		</div>

		<div class="header-actions" *ngIf="authenticated">
			<clr-dropdown>
				<button clrDropdownTrigger aria-label="user menu">
					<cds-icon size="24" shape="user"></cds-icon>
					<cds-icon shape="angle" direction="down"></cds-icon>
				</button>
				<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
					<h4 class="dropdown-header">Account Settings</h4>
					<a href="javascript:" (click)="openAccountSettings()" clrDropdownItem i18n>Account Profile</a>
					<a href="javascript:" *ngIf="env.enableCdss" (click)="openInstitutionSettings()" clrDropdownItem i18n>Institution Profile</a>
					<a href="javascript:" (click)="openChangePassword()" [clrDisabled]="authenticationService.usesOauth" clrDropdownItem i18n>Change Password</a>
					<a href="javascript:" (click)="open2FASettings()" clrDropdownItem i18n>Two-Factor Authentication Settings</a>
					<a href="javascript:" (click)="openAccountRemoval()" clrDropdownItem i18n>Delete Account</a>

					<ng-template [ngIf]="!env.hideInvoicingAndPayments">
						<div class="dropdown-divider" role="separator"></div>
						
						<h4 class="dropdown-header" i18n>Payments and Billing</h4>
						<a href="javascript:" (click)="openInvoicingSettings()" clrDropdownItem i18n>Invoice Settings</a>
						<a routerLink="/invoices" clrDropdownItem i18n>My Invoices</a>
						<a href="javascript:" (click)="openSavedCard()" clrDropdownItem i18n>Save a Payment Card</a>
						<a href="javascript:" (click)="openAddCredit()" clrDropdownItem i18n>Add Credit</a>
					</ng-template>

					@if (showAdmin) {
						<div class="dropdown-divider" role="separator"></div>
						<h4 class="dropdown-header" i18n>Admin</h4>
						<a routerLink="/dashboard/management" clrDropdownItem i18n>Management Dashboard</a>
						@if (isRoot) {
							<a routerLink="/tenancies" clrDropdownItem i18n>Tenancy Management</a>
							<a routerLink="/vending/articles" clrDropdownItem i18n>Article Management</a>
							<a routerLink="/vending/price-lists" clrDropdownItem i18n>Price List Management</a>
							<a routerLink="/vending/invoices" clrDropdownItem i18n>Invoices</a>
						}
					}
					<div class="dropdown-divider" role="separator"></div>
					<a href="javascript:" (click)="logout()" clrDropdownItem i18n>Log Out</a>
				</clr-dropdown-menu>
			</clr-dropdown>
		</div>
	</clr-header>
	<div>
		<div class="load-progress-line" [hidden]="!loading"></div>
	</div>

	<ng-content select="[subnav]"></ng-content>
	
    <div class="content-container">
        <ng-content></ng-content>
    </div>
</clr-main-container>


<app-settings-account #settingsAccount></app-settings-account>

<app-settings-two-factor #settings2FA></app-settings-two-factor>

<app-settings-password #settingsPassword></app-settings-password>

<app-settings-invoicing #settingsInvoicing></app-settings-invoicing>

<app-settings-saved-card #settingsSavedCard></app-settings-saved-card>

<app-settings-institution #settingsInstitution></app-settings-institution>

<app-topup-credit #topUpCredit (creditChanged)="creditChanged.emit()"></app-topup-credit>

<app-account-removal #accountRemoval (emailedTo)="onAccountRemovalInitiated($event)"></app-account-removal>
<app-message-box #messageBox></app-message-box>
