import { ContentChildren, Directive, ElementRef, EventEmitter, HostListener, Output, QueryList, Renderer2 } from '@angular/core';
import { ReorderableItemDirective } from './reorderable-item.directive';

@Directive({
    selector: '[appReorderableList]',
    standalone: false
})
export class ReorderableListDirective {
  private emptyElement: HTMLDivElement;

  @Output()
  reordered = new EventEmitter<ReorderableItemDirective[]>();

  @ContentChildren(ReorderableItemDirective)
  private allItems: QueryList<ReorderableItemDirective>;

  constructor(private listElement: ElementRef, private renderer: Renderer2) {
    this.emptyElement = renderer.createElement("div");
    this.emptyElement.classList.add("gap");
  }

  private draggingRole?: HTMLElement;
  private draggingOffsetY?: number; // diff between clientTop of dragged element and clientY of the initial mousedown
  private draggingTargetIndex?: number; // Where to place the dragged role once mouseup arrives

  roleMoveMouseDown(item: ReorderableItemDirective, event: MouseEvent) {
    event.preventDefault();

    const roleElement = item.element.nativeElement as HTMLElement;

    const origRect = roleElement.getBoundingClientRect();
    let origHeight = origRect.height;
    let origX = origRect.left + window.pageXOffset;
    let origY = origRect.top + window.pageYOffset;
    let origW = origRect.width;

    const computedStyle = window.getComputedStyle(roleElement);

    this.renderer.setStyle(this.emptyElement, "height", `${origHeight}px`);
    this.renderer.setStyle(this.emptyElement, "margin-top", computedStyle.marginTop);
    this.renderer.setStyle(this.emptyElement, "margin-bottom", computedStyle.marginBottom);
    this.renderer.setStyle(this.emptyElement, "margin-left", computedStyle.marginLeft);
    this.renderer.setStyle(this.emptyElement, "margin-right", computedStyle.marginRight);

    this.renderer.setStyle(roleElement, "left", `${origX}px`);
    this.renderer.setStyle(roleElement, "top", `${origY}px`);
    this.renderer.setStyle(roleElement, "width", `${origW}px`);

    this.renderer.addClass(roleElement, "moved");
    this.renderer.setStyle(roleElement, "position", "absolute");
    this.renderer.setStyle(roleElement, "margin", "0");

    let allRoleDivs = this.allItems.toArray().map(item => item.element.nativeElement as HTMLElement);
    this.draggingTargetIndex = allRoleDivs.indexOf(roleElement);

    this.renderer.insertBefore(this.listElement.nativeElement, this.emptyElement, roleElement);

    this.draggingRole = roleElement;
    this.draggingOffsetY = event.clientY - origY;
    this.renderer.setStyle(this.listElement.nativeElement, "cursor", "move");
  }

  @HostListener('document:mousemove', ['$event']) 
  private onMouseMove(event: MouseEvent) {
    if (!this.draggingRole)
      return;

    event.preventDefault();
    event.stopPropagation();

    let rolesDiv = this.listElement.nativeElement;
    if (event.clientY < rolesDiv.offsetTop || event.clientY > rolesDiv.offsetTop + rolesDiv.clientHeight)
      return; // Outside of the .roles column

    let newY = event.clientY - this.draggingOffsetY;
    // this.draggingRole.style.top = `${newY}px`;
    this.renderer.setStyle(this.draggingRole, "top", `${newY}px`);

    // Calculate the new target index and move the emptyElement into that location
    let allRoleDivs = this.allItems.toArray().map(item => item.element.nativeElement as HTMLElement);
    let newTargetIndex: number;
    let placeGapBefore: HTMLDivElement;
    let highestY = 0;

    for (let i = 0; i < allRoleDivs.length; i++) {
      let anotherRoleDiv = allRoleDivs[i] as HTMLDivElement;

      if (anotherRoleDiv === this.draggingRole)
        continue;

      const y = anotherRoleDiv.offsetTop;
      const h = anotherRoleDiv.clientHeight;

      if (event.clientY < y + h / 2) {
        newTargetIndex = i;
        placeGapBefore = anotherRoleDiv;
        break;
      }

      let bottom = y + h / 2;
      if (bottom > highestY)
        highestY = bottom;
    }

    if (newTargetIndex !== undefined) {
      this.renderer.insertBefore(this.listElement.nativeElement, this.emptyElement, placeGapBefore, true);
      // placeGapBefore.insertAdjacentElement('beforebegin', this.emptyElement);
      this.draggingTargetIndex = newTargetIndex;
    } else if (event.clientY > highestY) {
      rolesDiv.appendChild(this.emptyElement);
      this.draggingTargetIndex = allRoleDivs.length;
    }
  }

  @HostListener('document:mouseup', ['$event']) 
  private onMouseUp(event: MouseEvent) {
    if (!this.draggingRole)
      return;

    event.preventDefault();
    event.stopPropagation();

    this.emptyElement.remove();

    let allRoleDivs = this.allItems.toArray();

    let orderChanged = true;
    if (this.draggingTargetIndex < allRoleDivs.length) {
      // If the item is really to be repositioned, reinsert it in the new position
      if (allRoleDivs[this.draggingTargetIndex].element.nativeElement !== this.draggingRole) {
        this.renderer.insertBefore(this.listElement.nativeElement, this.draggingRole, allRoleDivs[this.draggingTargetIndex].element.nativeElement, true);
        
        // this.allItems doesn't update automatically, need to do it manually
        let oldPos = allRoleDivs.findIndex(dir => dir.element.nativeElement === this.draggingRole);
        const dir = allRoleDivs[oldPos];

        allRoleDivs.splice(this.draggingTargetIndex, 0, dir);

        if (oldPos > this.draggingTargetIndex)
          oldPos++;

        allRoleDivs.splice(oldPos, 1);
        this.allItems.reset(allRoleDivs);
      } else {
        orderChanged = false;
      }
    } else {
      this.renderer.appendChild(this.listElement.nativeElement, this.draggingRole);

      // this.allItems doesn't update automatically, need to do it manually
      let oldPos = allRoleDivs.findIndex(dir => dir.element.nativeElement === this.draggingRole);
      const dir = allRoleDivs[oldPos];
      allRoleDivs.splice(oldPos, 1);

      allRoleDivs.push(dir);
      this.allItems.reset(allRoleDivs);
    }

    this.renderer.removeClass(this.draggingRole, "moved");
    this.renderer.removeStyle(this.draggingRole, "position");
    this.renderer.removeStyle(this.draggingRole, "width");
    this.renderer.removeStyle(this.draggingRole, "height");
    this.renderer.removeStyle(this.draggingRole, "margin");
    
    this.draggingRole.style.width = undefined;
    this.draggingRole = undefined;
    this.draggingOffsetY = undefined;
    this.draggingTargetIndex = undefined;

    this.renderer.removeStyle(this.listElement.nativeElement, "cursor");

    if (orderChanged) {
      this.reordered.emit(this.allItems.toArray());
    }
  }

}
