import { DOCUMENT } from '@angular/common';
import { Component, Inject, enableProdMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from './payment.service';
import { AuthenticationService } from './authentication/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {
  title = 'Dedicaid';
  constructor(@Inject(DOCUMENT) private doc: any, auth: AuthenticationService, paymentService: PaymentService) {
    paymentService.getProviderConfig('stripe').toPromise().then(stripeConfig => {
      if (typeof stripeConfig === 'object' && stripeConfig['publicKey']) {
        this.enableStripe();
      }
    });

    auth.getFrontendConfig().then(config => {
      if (config.productionMode)
        enableProdMode();
    });
  }

  private enableStripe() {
    const s = this.doc.createElement('script');

    s.defer = true;
    s.async = true;
    s.setAttribute("crossorigin", "anonymous");
    s.src = "https://js.stripe.com/v3/";

    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }
}
