import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-account-unapproved',
    templateUrl: './account-unapproved.component.html',
    styleUrls: ['./account-unapproved.component.css'],
    standalone: false
})
export class AccountUnapprovedComponent {
  showMuwStrings = !!environment['showMuwStrings'];

  constructor() { }
}
