// Common code shared between the Angular app and cdss-submit.ts Node.js app

import { SuitabilityProblem, SuitabilityResult, serieSuitableForCdssRole } from "../dicom-definitions";
import { DicomAnalysisResultPatient, DicomAnalysisResultStudy } from "../dicom.service";
import { CdssModel, ModelListItem } from "./model";
import { NamedBlob } from "../dicom-common";
import { RoleHints, RoleHintsMap } from "../dicom-node/config";

export interface ModelStatus {
  acceptable?: boolean;
  problems?: Map<string, SuitabilityProblem[]>;
  modelData?: CdssModel;
  possibleRoleAssignment?: Map<string, string[]>;
  hasAmbiguity?: boolean;
  unassignedRoles?: string[];
}

export type ModelWithStatus = ModelListItem & AssignmentInformation & ModelStatus

export interface AssignmentInformation {
    roleAssignment?: Map<string, string>;
    extractedFeatures?: object;
}


export interface DicomInputResult {
    patient: DicomAnalysisResultPatient;
    study: DicomAnalysisResultStudy;
    fileMap: Map<string, NamedBlob>;
}

export function checkModelSuitability(modelData: CdssModel, patient: DicomAnalysisResultPatient, study: DicomAnalysisResultStudy, roleHints?: RoleHintsMap): ModelStatus & AssignmentInformation {
    let acceptable = true;
    let possibleRoleAssignment = new Map<string, string[]>(); // role id -> serie uid
    let results = new Map<string, SuitabilityResult>(); // The string is the role ID
    let problems: Map<string, SuitabilityProblem[]>; // ditto
    let hasAmbiguity = false;
    let unassignedRoles = [];

    modelData.data.roles.forEach(role => {
      let foundAcceptableSerie = false;
      study.series.forEach(serie => {
        let result = serieSuitableForCdssRole(serie, study, patient, role, roleHints?.[role.id]);

        if (result.problems.every(problem => problem.overridable)) {
          foundAcceptableSerie = true;

          const uids = possibleRoleAssignment.get(role.id) ?? [];
          uids.push(serie.uid);

          if (uids.length > 1)
            hasAmbiguity = true;

          possibleRoleAssignment.set(role.id, uids);

          results.set(role.id, result);
        }
      });

      if (!foundAcceptableSerie) {
        unassignedRoles.push(role.id);
        acceptable = false;
      }
    });

    let extractedFeatures = {};

    results.forEach((result, roleId) => {
      Object.assign(extractedFeatures, result.extractedFeatures);

      if (result.problems?.length > 0) {
        if (!problems)
          problems = new Map<string, SuitabilityProblem[]>();

        problems.set(roleId, result.problems);
      }
    });

    return { acceptable, possibleRoleAssignment, extractedFeatures, problems, modelData, hasAmbiguity, unassignedRoles };
}
