import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../auth.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-after-oauth-login',
    templateUrl: './after-oauth-login.component.html',
    styleUrls: ['./after-oauth-login.component.css'],
    standalone: false
})
export class AfterOauthLoginComponent implements OnInit {

  requesting: boolean = false;
  twoFactorNeeded: boolean = false;
  oauthCode: string;
  twoFactorCode = new FormControl('');
  redirectUrl: string;
  @ViewChild("twoFactorCodeElement") twoFactorElement: ElementRef<HTMLInputElement>;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['redirectUrl'];

      if (params['authcode']) {
        this.oauthCode = params['authcode'];
        this.doExchange();
      }
    });
  }

  doExchange() {
    let tfCode;

    if (this.twoFactorNeeded)
      tfCode = this.twoFactorCode.value;

    this.requesting = true;
    this.authenticationService.exchangeCodeForToken(this.oauthCode, tfCode).subscribe(result => {
      this.requesting = false;

      if (result.success)
        this.router.navigateByUrl(this.redirectUrl || '/');
      else if (result.twoFactorNeeded && !this.twoFactorNeeded) {
        this.twoFactorNeeded = true;
        window.setTimeout(() => this.twoFactorElement.nativeElement.focus(), 200);
      } else {
        window.alert($localize `Login operation failed.`);
        this.router.navigateByUrl('/login');
      }
    });
  }
}
