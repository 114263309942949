import { Pipe, PipeTransform } from '@angular/core';

const dicomDateCompliant = /(\d{4})(\d\d)(\d\d)/;
const dicomDateNonCompliant = /(\d{4})\.(\d\d)\.(\d\d)/;

@Pipe({
    name: 'dicomDate',
    standalone: false
})
export class DicomDatePipe implements PipeTransform {

  transform(value: string): Date {
    if (!value || typeof value !== 'string' || (value.length !== 8 && value.length !== 10))
      return null;

    let date = new Date(0);
    let re: RegExp;

    if (value.length === 8) {
      re = dicomDateCompliant;
    } else {
      re = dicomDateNonCompliant;
    }

    let groups = re.exec(value);
    date.setFullYear(parseInt(groups[1]), parseInt(groups[2]) - 1, parseInt(groups[3]));

    return date;
  }

}
