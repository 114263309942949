<form clrForm [formGroup]="localForm">
    <clr-input-container>
      <label i18n>E-mail</label>
      <input clrInput formControlName="email" />
    </clr-input-container>

    <clr-control-container>
      <label i18n>New password</label>
      <input clrControl type="password" formControlName="password1" size="40" autocomplete="new-password" tabindex="2" />
      <clr-control-error *clrIfError="'minlength'" i18n>The password must have at least 8 characters.</clr-control-error>
      <clr-control-error *clrIfError="'complexity'" i18n>The password must include at least one lower-case and upper-case character, a digit and a symbol.</clr-control-error>
    </clr-control-container>

    <clr-password-container>
      <label i18n>Repeat new password</label>
      <input clrPassword formControlName="password2" size="40" autocomplete="new-password"  tabindex="3" />
    </clr-password-container>

    <clr-alert [clrAlertType]="'warning'" [clrAlertClosable]="false" *ngIf="localForm.hasError('notSame')">
      <clr-alert-item i18n>
        Passwords do not match.
      </clr-alert-item>
    </clr-alert>

  </form>