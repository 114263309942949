import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, map, Observable, switchMap } from 'rxjs';
import { ResultRange } from 'src/common';
import { AuthenticationService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TenancyService {

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  public getTenancies(offset = 0, count?: number, nameFilter?: string): Observable<ResultRange<TenancyData>> {
    let params = new HttpParams();
    if (offset !== undefined)
      params = params.set("from", offset.toFixed(0));

    if (count !== undefined)
      params = params.set("count", count.toFixed(0));

    if (nameFilter)
      params = params.set("name", nameFilter);
    
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.get<TenancyData[]>(config.authServiceUrl + '/api/v1/tenancy', { params, observe: "response" })
      .pipe(map(resp => {
        let range = resp.headers.get("content-range");
        return {
          totalCount: parseInt(range.split('/')[1]),
          data: resp.body,
        };
      }));
    }));
  }

  public createTenancy(tenancy: TenancyData): Observable<void> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.post<void>(config.authServiceUrl + '/api/v1/tenancy', tenancy);
    }));
  }

  public getTenancy(id: string): Observable<TenancyData> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.get<TenancyData>(config.authServiceUrl + `/api/v1/tenancy/${id}`);
    }));
  }

  public deleteTenancy(id: string): Observable<void> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.delete<void>(config.authServiceUrl + `/api/v1/tenancy/${id}`);
    }));
  }

  public modifyTenancy(id: string, data: TenancyData): Observable<void> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.put<void>(config.authServiceUrl + `/api/v1/tenancy/${id}`, data);
    }));
  }
}

export interface TenancyData {
  id?: string;
  name: string;
  region?: string;
}
