import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, passwordQualityCheck } from '../auth.service';

@Component({
    selector: 'auth-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.css'],
    standalone: false
})
export class PasswordRecoveryComponent {
  busy = false;
  passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required, passwordQualityCheck]),
    password2: new FormControl('', Validators.required),
  });

  private uid: string;
  private recoveryKey: string;

  constructor(route: ActivatedRoute, private router: Router, private auth: AuthenticationService) {
    route.queryParams.subscribe(params => {
      this.uid = params.uid;
      this.recoveryKey = params.recoveryKey;
    });
  }

  doChangePassword() {
    if (!this.passwordForm.valid)
      return;

    let password = this.passwordForm.get('password').value;
    let password2 = this.passwordForm.get('password2').value;

    if (password !== password2) {
      this.passwordForm.get('password2').setErrors({ noMatch: true });
      return;
    }

    this.busy = true;

    this.auth.setForgottenPassword(this.uid, this.recoveryKey, password).subscribe(() => {
      this.router.navigateByUrl('/');
    }, err => {
      this.busy = false;
      console.error("Failed to change password", err);
      window.alert($localize `Failed to change your password.`);
    });
  }

  get password2() {
    return this.passwordForm.get('password2');
  }

}
