import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService, InstitutionProfile } from 'src/app/authentication/auth.service';

@Component({
    selector: 'app-settings-institution',
    templateUrl: './settings-institution.component.html',
    styleUrls: ['./settings-institution.component.css'],
    standalone: false
})
export class SettingsInstitutionComponent {
  opened = false;
  busy = false;

  currentLogo: string;

  form = new FormGroup({
    institutionName: new FormControl(''),
    institutionLogo: new FormControl(''),
  });

  @ViewChild('institutionLogo', { static: false })
  institutionLogoInput: ElementRef<HTMLInputElement>;

  constructor(private authenticationService: AuthenticationService) { }

  open() {
    

    this.authenticationService.getReportDetailsProfile(this.authenticationService.uid).subscribe(details => {
      this.opened = true;
      this.currentLogo = "";
      this.form.get('institutionName').setValue(details.institutionName);

      if (details.institutionLogo) {
        let blob = new Blob([ details.institutionLogo ], { type: 'image/png' })

        let reader = new FileReader();
        reader.onload = event => {
          this.currentLogo = event.target.result as string;
        };

        reader.readAsDataURL(blob);
      }
    }, err => {
      window.alert($localize `Error getting report details profile!`);
    })
  }

  save() {
    this.busy = true;

    let logoPromise: Promise<ArrayBuffer> = Promise.resolve(undefined);
    if (this.institutionLogoInput.nativeElement.files.length > 0) {
      let file = this.institutionLogoInput.nativeElement.files.item(0);
      logoPromise = getFileArrayBuffer(file);
    }

    logoPromise.then(logoData => {
      let profile: InstitutionProfile = {
        institutionLogo: logoData,
        institutionName: this.form.get('institutionName').value,
      };

      return this.authenticationService.setReportDetailsProfile(this.authenticationService.uid, profile).toPromise();
    })
    .then(() => {
      this.busy = false;
      this.opened = false;
    })
    .catch(err => {
      this.busy = true;
      console.error("Error saving report details profile", err);
      window.alert($localize `Error saving your data.`);
    });
  }

}

function getFileArrayBuffer(file: File) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = error => reject(error);
  });
}
