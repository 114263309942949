import { ApplicationRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-editable-text',
    templateUrl: './editable-text.component.html',
    styleUrls: ['./editable-text.component.scss'],
    standalone: false
})
export class EditableTextComponent {
  @Input()
  text: string;

  @Input()
  inputFieldSize = 20;

  @Input()
  editable = true;

  @Output()
  textChange = new EventEmitter<string>();

  editing = false;
  inputControl = new FormControl('');

  @ViewChild('textInput', { static: false })
  textInput: ElementRef<HTMLInputElement>;

  constructor(private appRef: ApplicationRef) { }

  startEditing() {
    if (!this.editable)
      return;
      
    this.inputControl.setValue(this.text);
    this.editing = true;

    // Force the <input> to appear _now_...
    this.appRef.tick();

    // ...so that we can set focus here
    this.textInput.nativeElement.focus();
  }

  finishEditing() {
    this.text = this.inputControl.value;
    this.editing = false;
    this.textChange.emit(this.text);
  }

  cancelEditing() {
    this.editing = false;
  }

}
