import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-device-login-done',
    templateUrl: './device-login-done.component.html',
    styleUrls: ['./device-login-done.component.css'],
    standalone: false
})
export class DeviceLoginDoneComponent {

  constructor() { }
  doClose() {
    window.close();
  }

}
