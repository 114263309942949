import { ChangeDetectionStrategy, Component, effect, input, model, signal } from '@angular/core';
import { WorkspaceBackendService, WorkspaceListItem } from 'cdss-common-lib';
import { map, Observable, tap } from 'rxjs';


@Component({
    selector: 'app-workspace-bar',
    templateUrl: './workspace-bar.component.html',
    styleUrls: ['./workspace-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkspaceBarComponent {
  loading = false;
  
  asyncWorkspaces: Observable<WorkspaceListItemWithType[]> | undefined;

  public workspaceKind = input('cdss');
  public workspaceId = model<string>(null);
  
  workspace = signal<WorkspaceListItemWithType>(PERSONAL_WORKSPACE);

  constructor(private backend: WorkspaceBackendService) {
    effect(() => {
      if (!this.workspaceId()) {
        this.workspace.set(PERSONAL_WORKSPACE);
      } else {
        this.backend.getWorkspace(this.workspaceKind(), this.workspaceId()).subscribe(wsData => {
          this.workspace.set({
            id: this.workspaceId(),
            name: wsData.name,
            type: WorkspaceType.Workspace,
          });
        });
      }
    }, { allowSignalWrites: true });
  }

  fetchWorkspaces(filter?: string) {
    this.loading = true;
    this.asyncWorkspaces = this.backend.listWorkspaces(this.workspaceKind()).pipe(tap(() => (this.loading = false)), map(result => {
      let mapped = result.map(item => Object.assign({type: WorkspaceType.Workspace}, item));

      mapped.unshift(PERSONAL_WORKSPACE);

      // Do filtering on client side
      return mapped.filter(item => {
        if (filter)
          return item.name.toLowerCase().includes(filter.toLowerCase());
        return true;
      })
    }));
  }

  onWorkspaceChange(ws: WorkspaceListItemWithType) {
    if (ws.type === WorkspaceType.User) {
      this.workspaceId.set(null);
    } else {
      this.workspaceId.set(ws.id);
    }
    this.workspace.set(ws);
  }

}

enum WorkspaceType {
  Workspace = "workspace", // real workspace
  User = "user", // i.e. no workspace
}

interface WorkspaceListItemWithType extends WorkspaceListItem {
  type: WorkspaceType;
}


const PERSONAL_WORKSPACE = {
  name: $localize `Personal Workspace`,
  id: null,
  type: WorkspaceType.User,
};
