// This is to make the RadiomicsProjectView component more reusable

import { Observable } from "rxjs";

// https://stackoverflow.com/questions/52304190/is-there-a-way-to-inject-a-different-service-based-on-route-in-angular-2
export interface GeneratedFeatureNamesProvider {
	getFeatureNames(project: GenericNamedProject): Observable<SampleFeature[]>;
}

export interface GenericNamedProject {
	name: string;
	sampleCount: number;
	customColumns?: string[];
}
export interface SampleFeature {
	name: string;
	editable?: boolean;
	isLabel?: boolean;
}
  