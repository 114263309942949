import { HostListener, Injectable, NgZone, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpEventType, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as base64 from 'base64-arraybuffer';
import { base64url } from 'jose';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthServiceUrlProvider } from 'cdss-common-lib';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy, AuthServiceUrlProvider {
  private token: string = null;
  private _uid: string = null;
  private refreshTimer?: number;
  public usesTwoFactor: boolean;
  public publicKey: string;
  public privateKey: string;
  public usesOauth = false;
  private expiry: Date;
  private _roles: string[];

  private _scopeId?: string;
  private _scopeLevel?: "global" | "tenant";

  private _twoFactorStateChanged = new Subject<boolean>();
  private _isAuthenticated = new BehaviorSubject<boolean>(false);
  private frontendConfig: Promise<FrontendConfig>;

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService, private ngZone: NgZone) {
    // There's a dependency loop between AuthenticationService and AuthenticationInterceptor
    // We break it by doing the HTTP request in a promise of its own, i.e. it gets run after this constructor.
    this.frontendConfig = new Promise<FrontendConfig>((resolve, reject) => {
      window.setTimeout(() => {
        this.http.get<FrontendConfig>('/config.json').subscribe(res => resolve(res), err => reject(err));
      }, 0);
    });
  }

  getAuthServiceUrl(): Promise<string> {
    return this.getFrontendConfig().then(config => config.authServiceUrl);
  }

  ngOnDestroy(): void {
    if (this.refreshTimer)
      window.clearTimeout(this.refreshTimer);
  }

  public isAuthenticated(): boolean {
    return this.token !== null;
  }

  public isAuthenticatedObservable() {
    return this._isAuthenticated.asObservable();
  }

  public getFrontendConfig() {
    return this.frontendConfig;
  }

  public getToken(): string {
    return this.token;
  }

  private saveToken(token: string): void {
    this.token = token;
    this._isAuthenticated.next(true);
    
    try {
      let decoded = this.decodeJwtContentsInsecure(this.token);

      if (decoded.exp)
        this.expiry = new Date(decoded.exp * 1000);
      else
        this.expiry = new Date(new Date().getTime() + 1000*60*10);

      this._scopeId = decoded.sid;
      this._scopeLevel = decoded.sl;

      this.cookieService.set("token", token, this.expiry, "/", null, environment.secureCookie, "Strict");
    
      this.usesOauth = decoded['oauth'] as boolean;
    } catch(e) {
      console.error(e);
      this.usesOauth = false;
    }
  }

  public forgetToken(): void {
    this.token = null;
    window.clearTimeout(this.refreshTimer);
    this.cookieService.delete("token", "/");
  }

  private processAuthenticationResponse(response) {
    this.saveToken(response['token']);

    this._uid = response['uid'];
    this.publicKey = response['publicKey'];
    this.privateKey = response['privateKey'];
    this.usesTwoFactor = response['uses2FA'];
    this._roles = response['roles'];
  }

  public get twoFactorStateChanged() {
    return this._twoFactorStateChanged;
  }

  public get roles(): string[] {
    return this._roles;
  }

  public hasRole(role: string): boolean {
    if (!this.isAuthenticated())
      return false;
      
    if (this._roles.includes(role))
      return true;
    if (this._roles.includes('root'))
      return true;
    return false;
  }

  public getBackendConfiguration(): Observable<AuthBackendConfiguration> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.get<AuthBackendConfiguration>(config.authServiceUrl + '/api/v1/config');
    }));
  }

  public authenticate(email: string, password: string, twoFactorCode?: string) : Observable<AuthenticationResult> {
    let headers;

    if (twoFactorCode)
      headers = { 'X-2FA-Code': twoFactorCode };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post(config.authServiceUrl + '/api/v1/user/login', {
        email: email,
        password: password
      }, {
        observe: 'response',
        headers: headers
      }).pipe(map(response => {

        this.processAuthenticationResponse(response.body);

        if (this.refreshTimer)
          window.clearTimeout(this.refreshTimer);
        this.createTokenRefreshTimer();

        return { uid: this.uid, success: true };
      }), catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          return of({ twoFactorNeeded: true, success: false });
        }

        let errorMessage = response.message;
        if (typeof response.error === 'object' && response.error.message)
          errorMessage = response.error.message;

        if (errorMessage === "unapproved")
          this.router.navigateByUrl('/login/unapproved');

        return of({ success: false, message: errorMessage });
      }));
    }));
  }

  public exchangeCodeForToken(code: string, twoFactorCode?: string) : Observable<AuthenticationResult> {
    let headers;

    if (twoFactorCode)
      headers = { 'X-2FA-Code': twoFactorCode };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post(config.authServiceUrl + '/api/v1/user/login/oauth/token', { code: code }, {
        observe: 'response',
        headers: headers
      }).pipe(map(response => {
        this.processAuthenticationResponse(response.body);

        if (this.refreshTimer)
          window.clearTimeout(this.refreshTimer);
        this.createTokenRefreshTimer();

        return { uid: this.uid, success: true };
      }), catchError(response => {
        if (response.status === 401) {
          return of({ twoFactorNeeded: true, success: false });
        }

        return of({ success: false });
      }));
    }));
}

  get uid(): string {
    return this._uid;
  }

  @HostListener('window:focus', ['$event'])
  private onWindowFocused() {
    if (this.token && this.expiry) {
      let now = new Date();
      if (now.getTime() > this.expiry.getTime())
        this.refreshToken();
    }
  }

  private refreshToken() {
    return from(this.frontendConfig).subscribe(config => {
      this.http.post<object>(config.authServiceUrl + '/api/v1/user/refreshToken', '')
      .pipe(catchError(err => of(null)))
      .subscribe(obj => {
        if (obj) {
          this.processAuthenticationResponse(obj);
          this.createTokenRefreshTimer();
        } else {
          this.cookieService.delete('token');
          this.router.navigateByUrl('/login');
          this.token = null;
          window.clearTimeout(this.refreshTimer);
        }
      });
    });
  }

  public reviveCookieToken(): Observable<AuthenticationResult> {
    let cookieToken = this.cookieService.get("token");
    if (!cookieToken)
      return of({ success: false });
    
    return this.adoptToken(cookieToken);
  }

  // Takes an externally provided token and attempts to do a refresh
  public adoptToken(token: string) : Observable<AuthenticationResult> {
    this.token = token;
    window.clearTimeout(this.refreshTimer);

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<object>(config.authServiceUrl + '/api/v1/user/refreshToken', '')
      .pipe(map(obj => {
          let res = new AuthenticationResult();
          this.processAuthenticationResponse(obj);

          this.createTokenRefreshTimer();

          res.success = true;
          res.twoFactorNeeded = obj['uses2FA'];
          res.uid = obj['uid'];

          return res;
      }), catchError(err => {
        this.token = null;
        this.cookieService.delete("token", "/");

        return of({ success: false });
      }));
    }));
  }

  public getProfile(uid: string) : Observable<UserProfile> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.get<UserProfile>(config.authServiceUrl + '/api/v1/user/' + uid);
    }));
  }

  public modifyProfile(uid: string, profile: UserProfile, twoFactorCode?: string) : Observable<HttpResponse<void>> {
    let headers;

    if (twoFactorCode)
      headers = { 'X-2FA-Code': twoFactorCode };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.put<void>(config.authServiceUrl + '/api/v1/user/' + uid, profile, { observe: 'response', headers: headers });
    }));
  }

  public get2FAState(uid: string): Observable<boolean> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.get(config.authServiceUrl + '/api/v1/user/' + uid + '/2fa').pipe(map(res => res['enabled']));
    }));
  }

  // Returns the new secret if enabled is true
  // twoFactorCode only needed when disabling 2FA
  public set2FAState(uid: string, enabled: boolean, twoFactorCode?: string) : Observable<string | boolean> {
    let headers;

    if (twoFactorCode)
      headers = { 'X-2FA-Code': twoFactorCode };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.put(config.authServiceUrl + '/api/v1/user/' + uid + '/2fa',
        { enabled },
        { headers: headers })
      .pipe(map(res => {
        if (!res['enabled'])
          this._twoFactorStateChanged.next(false);
          
        return res['secret'] || res['enabled'];
      }));
    }));
  }

  // To be called after set2FAState() with enabled = true
  public confirm2FAState(uid: string, otpCode: string) : Observable<TFARecoveryCodes> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<TFARecoveryCodes>(config.authServiceUrl + '/api/v1/user/' + uid + '/2fa', {},
        { headers: { 'X-2FA-Code': otpCode } }).pipe(map(res => {

          this._twoFactorStateChanged.next(true);
          return res;
        }));
    }));
  }

  public getInvoicingSettings(uid: string) : Observable<InvoicingSettings> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.get<InvoicingSettings>(config.authServiceUrl + '/api/v1/user/' + uid + '/invoicing');
    }));
  }

  public setInvoicingSettings(uid: string, settings: InvoicingSettings) : Observable<HttpResponse<void>> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.put<void>(config.authServiceUrl + '/api/v1/user/' + uid + '/invoicing', settings, { observe: 'response' });
    }));
  }

  public getReportDetailsProfile(uid: string): Observable<InstitutionProfile> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.get<object>(config.authServiceUrl + `/api/v1/user/${uid}/reportDetails`).pipe(map(data => {
        let logoData = data['institutionLogo'];
        return {
          institutionName: data['institutionName'],
          institutionLogo: logoData ? base64.decode(logoData) : null,
        };
      }));
    }));
  }

  public setReportDetailsProfile(uid: string, profile: InstitutionProfile): Observable<void> {
    let logoEncoded;

    if (profile.institutionLogo)
      logoEncoded = base64.encode(profile.institutionLogo);

    let req = {
      institutionName: profile.institutionName,
      institutionLogo: logoEncoded,
    };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.put<void>(config.authServiceUrl + `/api/v1/user/${uid}/reportDetails`, req);
    }));
  }

  public getSocialLoginProviders(): Observable<SocialLoginProvider[]> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.get<SocialLoginProvider[]>(config.authServiceUrl + '/api/v1/user/login/providers');
    }));
  }

  public registerUserFromInvitation(invitationId: string, invitationKey: string, password: string): Observable<void> {
    const data = {
      key: invitationKey,
      password,
    };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<object>(config.authServiceUrl + `/api/v1/user/invite/${invitationId}/use`, data).pipe(map(resp => {
        if (this.refreshTimer)
          window.clearTimeout(this.refreshTimer);
          
        this.createTokenRefreshTimer();
        
        this.processAuthenticationResponse(resp);
        return null;
      }))
    }));
  }

  public registerUser(email: string, password: string, recaptchaToken: string): Observable<void> {
    let request = {
      email: email,
      password: password,
      token: recaptchaToken,
    }

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<void>(config.authServiceUrl + '/api/v1/user', request);
    }));
  }

  public activateAccount(uid: string, code: string): Observable<boolean> {
    let request = {
      code: code,
    };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<object>(config.authServiceUrl + '/api/v1/user/' + uid + '/activate', request)
        .pipe(map(resp => {
          if (resp['unapproved']) {
            return false;
          }

          if (this.refreshTimer)
            window.clearTimeout(this.refreshTimer);
            
          this.createTokenRefreshTimer();
          this.processAuthenticationResponse(resp);
          return true;
        }));
      }));
  }

  public rejectAccount(uid: string, code: string): Observable<void> {
    let request = {
      code: code,
    };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<void>(config.authServiceUrl + '/api/v1/user/' + uid + '/rejectActivation', request);
    }));
  }

  public requestAccountDeletion(): Observable<void> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<void>(config.authServiceUrl + '/api/v1/user/' + this.uid + '/requestDeletion', '');
    }));
  }

  public requestForgottenPasswordEmail(email: string): Observable<void> {
    let body = {
      email,
    };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<void>(config.authServiceUrl + '/api/v1/user/forgottenPassword', body);
    }));
  }

  public setForgottenPassword(uid: string, recoveryKey: string, newPassword: string) {
    let req = {
      password: newPassword,
      recoveryKey,
    };

    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.put(config.authServiceUrl + `/api/v1/user/${uid}/setForgottenPassword`, req);
    }));
  }

  public getAccountDeletionDetails(uid: string, code: string) {
    let params = new HttpParams().append('code', code);
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.get<AccountDeletionDetails>(config.authServiceUrl + `/api/v1/user/${uid}/approveDeletion`, { params });
    }));
  }

  public cancelDeletion(uid: string, code: string) {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post(config.authServiceUrl + `/api/v1/user/${uid}/cancelDeletion`, { code });
    }));
  }

  // User must be authenticated!
  public approveDeletion(code: string) {
    if (!this._uid)
      return throwError(new Error("Not logged in"));
    
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post(config.authServiceUrl + `/api/v1/user/${this._uid}/approveDeletion`, { code });
    }));
  }

  private createTokenRefreshTimer() {
    let refreshDelay = 1000*60*10;
    try {
      let decoded = this.decodeJwtContentsInsecure(this.token);

      if (decoded.exp) {
        let diff = (decoded.exp - new Date().getTime() / 1000);
        if (diff >= 0) {
          refreshDelay = (diff / 3 * 2) * 1000;
        }
      }
    } catch (e) {}

    // Why this is needed: https://www.protractortest.org/#/timeouts
    // Without this, E2E tests will think that the page is still loading due to the timer below.
    this.ngZone.runOutsideAngular(() => {
      this.refreshTimer = window.setTimeout(() => {
        this.ngZone.run(() => this.refreshToken());
      }, refreshDelay);
    });
  }

  private decodeJwtContentsInsecure(jwt: string): any {
    const textDecoder = new TextDecoder();

    return JSON.parse(textDecoder.decode(base64url.decode(jwt.split('.')[1])))
  }

  public get scopeLevel() {
    return this._scopeLevel;
  }

  public get scopeId() {
    return this._scopeId;
  }

  public continueAuthentication(email: string): Observable<AuthType | null> {
    return from(this.frontendConfig).pipe(switchMap(config => {
      return this.http.post<AuthType>(config.authServiceUrl + '/api/v1/user/login/continue', { email })
        .pipe(catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 404)
            return of(null);
          throw err;
        }));
    }));
  }
}

export interface SocialLoginProvider {
  id: string;
  name: string;
  bgcolor: string;
  icon: string;
  url: string;
}

export class AuthenticationResult {
  uid?: string;
  success: boolean;
  twoFactorNeeded?: boolean;
  message?: string;
}

export interface UserProfile {
  email?: string;
  affiliation?: string;
  name?: string;
  password?: string; // Only towards the server
  phone?: string;
}

export interface UserObject extends UserProfile {
  id: string;
  oauthProvider?: string;
  created: Date;
  priceList?: string;
  companyName?: string;
  uses2FA?: boolean;
  roles?: string;
  unapproved?: boolean;

  scopeLevel?: "global" | "tenant";
  scopeId?: string;

  groups: UserGroupMembership[];
}

export interface UserGroupMembership {
  id: string;
  name: string;
}

export class InvoicingSettings {
  companyName?: string;
  companyID?: string;
  vatID?: string;
  addressLine1?: string;
	addressLine2?: string;
	city?: string;
	zip?: string;
	state?: string;
	countryCode?: string;
  billingPeriod?: "monthly" | "quarterly" | "yearly";
}

export interface InstitutionProfile {
  institutionName?: string;
  institutionLogo?: ArrayBuffer;
}

export interface AuthBackendConfiguration {
  accountApprovalNeeded: boolean;
  enableRecaptcha: boolean;
}

export interface AccountDeletionDetails {
  oauth?: string;
  '2fa'?: boolean;
}

export interface TFARecoveryCodes {
  recoveryCodes: string[];
}

export interface FrontendConfig {
  authServiceUrl: string;
  productionMode?: boolean;
  features?: FeaturesConfig;
}

export interface FeaturesConfig {
  enableRadiomics?: boolean;
  enableCdss?: boolean;
  enableAutoml?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService.isAuthenticated()) {
      req = req.clone({
        setHeaders: {
          "Authorization": "Bearer " + this.authenticationService.getToken()
        }
      });
    }

    // CSF-336
    window['dedicaidTestInterceptor']?.(req);

    return next.handle(req).pipe(map(event => {
      if (event.type === HttpEventType.ResponseHeader) {
        if (!req.url.endsWith("/refreshToken") && !req.url.endsWith("/user/login")) {
          if (event.status === 401)
            this.router.navigateByUrl("/login");
        }
      }
      return event;
    }));
  }
}

export function passwordQualityCheck(control: AbstractControl<string>): ValidationErrors | null {
  const password = control.value;

  if (password.length < 8)
    return { "minlength": true };

  if (!/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password))
    return { "complexity": true };
  
  let foundSymbol = false;
  for (let i = 0; i < password.length; i++) {
    const code = password.charCodeAt(i);

    // https://owasp.org/www-community/password-special-characters
    if ((code >= 0x20 && code <= 0x2f) || (code >= 0x3a && code <= 0x40) || (code >= 0x5b && code <= 0x60) || (code >= 0x7b && code <= 0x7e)) {
      foundSymbol = true;
      break;
    }
  }

  if (!foundSymbol)
    return { "complexity": true };

  return null;
}

export interface AuthType {
  continue: string;
}
