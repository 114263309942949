import { ChangeDetectionStrategy, Component, input, model, OnInit, output, signal } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/auth.service';
import { TenancyData, TenancyService } from 'src/app/tenancies/tenancy.service';

@Component({
    selector: 'app-scope-bar',
    templateUrl: './scope-bar.component.html',
    styleUrls: ['./scope-bar.component.scss'],
    standalone: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopeBarComponent implements OnInit {
  loading = signal(false);

  asyncTenancies = signal<TenancyData[]>(null);

  scope = input<Scope>(null);
  scopeChange = output<Scope>();

  scopeLevel = signal<"global" | "tenant">("global");
  scopeId = signal<string | null>(null);

  noGlobalScope = input(false);

  tenancy = model<TenancyData | undefined>(undefined);
  canListTenancies = false;

  constructor(private backend: TenancyService, authService: AuthenticationService) {
    this.canListTenancies = authService.hasRole('root');

    this.tenancy.subscribe(() => {
      const tenancy = this.tenancy();
      this.scopeId.set(tenancy?.id);
  
      if (this.scopeLevel() === "tenant" && this.scopeId()) {
        this.scopeChange.emit({
          scope: "tenant",
          scopeId: this.scopeId(),
        });
      }
    });
  }

  ngOnInit(): void {
    this.scopeLevel.set(this.scope()?.scope);
    this.scopeId.set(this.scope()?.scope === 'tenant' ? this.scope()?.scopeId : undefined);

    if (this.scopeId()) {
      this.backend.getTenancy(this.scopeId()).subscribe({
        next: tenancyData => {
          this.tenancy.set(tenancyData);
        },
      });
    } else {
      this.tenancy.set(undefined);
    }
  }

  fetchTenancies(filter?: string) {
    this.loading.set(true);
    this.backend.getTenancies(0, undefined, filter).pipe(tap(() => (this.loading.set(false))), map(result => result.data)).subscribe(list => {
      this.asyncTenancies.set(list);
    });
  }

  onScopeLevelChange(scope) {
    this.scopeLevel.set(scope);

    if (scope === "global") {
      this.scopeChange.emit({ scope: "global" });
    } else if (this.scopeId()) {
      // emit only if we have full data
      this.scopeChange.emit({
        scope: "tenant",
        scopeId: this.scopeId(),
      });
    }
  }
}

export interface Scope {
  scope?: "global" | "tenant";
  scopeId?: string;
}
