<nav class="subnav">
    <span>
        <cds-icon shape="tree-view"></cds-icon>
        <span i18n>Scope:</span>
    </span>

    <div class="clr-select-wrapper">
        <select class="clr-select nav-elem" [ngModel]="scopeLevel()" (ngModelChange)="onScopeLevelChange($event)" [disabled]="!canListTenancies || noGlobalScope()">
            <option *ngIf="!noGlobalScope()" value="global" i18n>Global</option>
            <option value="tenant" i18n>Tenancy</option>
        </select>
    </div>

    @if (scopeLevel() === 'tenant') {
        <span class="nav-elem">/</span>

        <clr-combobox
            [(ngModel)]="tenancy"
            required
            [disabled]="!canListTenancies"
            [clrLoading]="loading()"
            (clrInputChange)="fetchTenancies($event)"
            (clrOpenChange)="$event ? fetchTenancies() : null"
        >
            <ng-container *clrOptionSelected="let selected">
                <cds-icon shape="building"></cds-icon>
                {{ selected?.name }}
            </ng-container>
            <clr-options>
                <clr-option
                    *clrOptionItems="let tt of asyncTenancies(); field: 'name'"
                    [clrValue]="tt">
                    <cds-icon shape="building"></cds-icon>
                    {{ tt.name }}
                </clr-option>
            </clr-options>
        </clr-combobox>
    }

</nav>
