import { Injectable, Pipe, PipeTransform } from '@angular/core';

// Like Number.toFixed(), but eliminates final zeroes after the decimal point
// Does NOT do rounding of removed decimal digits.
@Pipe({
    name: 'toFixed',
    standalone: false
})
@Injectable({
  providedIn: 'root'
})
export class ToFixedPipe implements PipeTransform {

  transform(num: number, fixed: number): string {

    //return num.toString();
    
    const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed) + '})?');
    let trimmed = num.toFixed(10).match(re)[0];

    if (trimmed.indexOf('.') !== -1) {
      // Remove all decimal zeroes on the right
      for (let i = trimmed.length-1; i >= 0; i--) {
        if (trimmed[i] !== '0') {
          trimmed = trimmed.substring(0, i+1);
          break;
        }
      }

      if (trimmed.endsWith('.'))
        trimmed = trimmed.substring(0, trimmed.length-1);
    }

    return trimmed;
  }

}
